import { Row, Space } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

const MaintenancePage = () => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Space align="center" direction="vertical">
        <ToolOutlined style={{ fontSize: '100px' }} />
        <h1>Website Under Construction</h1>
      </Space>
    </Row>
  );
};

export default MaintenancePage;
