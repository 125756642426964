import { useState, useMemo } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { PlacesAutocomplete } from '../places-autocomplete';
import { AutocompleteAddress, LatLng } from '../../ad-form';
import { getGoogleMapAddress } from '../../../utils/get-google-map-address';

type MapProps = {
  handleAutocomplete?: (address: AutocompleteAddress) => void;
  handleMapClick?: (address: AutocompleteAddress) => void;
  // точка на карте в режиме редактирования объявления
  currentLatLng: LatLng;
  showAutoComplete: boolean;
};

export const Map = ({
  handleAutocomplete,
  handleMapClick,
  currentLatLng,
  showAutoComplete = true,
}: MapProps) => {
  const [selected, setSelected] = useState<LatLng | null>(null);

  const defaultMark = useMemo(
    () => currentLatLng || { lat: -3.291082, lng: 119.879551 },
    [currentLatLng],
  );

  // при взаимодействии с картой, сбрасываем зум
  // иначе устанавливаем его дфеолтное значение в 18 при редактировании объявления и 7 при создании
  const zoom = selected ? undefined : currentLatLng ? 18 : 7;

  const onSelect = (latLng: LatLng) => {
    setSelected(latLng);
  };

  const onMapClick = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      const address = await getGoogleMapAddress(latLng);
      setSelected(address.coords);

      handleMapClick?.(address);
    }
  };

  return (
    <>
      {showAutoComplete && (
        <PlacesAutocomplete
          setSelected={onSelect}
          handleAutocomplete={handleAutocomplete}
        />
      )}

      <GoogleMap
        zoom={zoom}
        center={selected || defaultMark}
        mapContainerClassName="google-map-container"
        onClick={onMapClick}
      >
        {(selected || currentLatLng) && (
          <Marker position={selected || defaultMark} />
        )}
      </GoogleMap>
    </>
  );
};
