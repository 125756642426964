import React, { useState } from 'react';
import { Col, Empty, Row, Space } from 'antd';
import { Announcement } from '../../types/response/announcement';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Text, Title } from '../../components-library';
import { StyledArrow, StyledCarousel, StyledImage, StyledSpace } from './style';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

type AdItemProps = {
  ad: Announcement;
  onDeleteItem?: (id: string | number) => Promise<void>;
};

const realEstateObjectTypeMap = {
  APARTMENTS: 'Apartments',
  VILLA: 'Villa',
  GUESTHOUSE: 'Guesthouse',
  LAND: 'Land',
  OFFICE: 'Office',
  BUILDING: 'Bulding',
  COWORKING: 'Coworking',
};

const AdItem = ({ ad, onDeleteItem }: AdItemProps) => {
  // Добавляем состояние для отслеживания наведения мыши
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate();

  const renderCarousel = () => {
    if (ad.photos.length > 0) {
      return (
        <StyledCarousel
          arrows={true}
          prevArrow={
            <StyledArrow rotated>
              <Icon name={'arrowSlider'} />
            </StyledArrow>
          }
          nextArrow={
            <StyledArrow>
              <Icon name={'arrowSlider'} />
            </StyledArrow>
          }
        >
          {ad.photos.map((url, i) => (
            <StyledImage key={i} src={url} preview={false} />
          ))}
        </StyledCarousel>
      );
    } else {
      return <Empty description={false} style={{ height: '270px' }} />;
    }
  };

  // Обработчики событий для показа и скрытия кнопок при наведении мыши
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Row
      gutter={24}
      style={{ height: '270px', marginRight: '0' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Col span={6}>{renderCarousel()}</Col>
      <Col span={18}>
        <Space direction="vertical">
          <Title level={4}>
            {realEstateObjectTypeMap[ad.realEstateObject.realEstateObjectType]}
          </Title>
          <Title
            level={5}
            style={{ color: 'var(--grey-dark)', fontSize: '18px' }}
          >
            {ad.realEstateObject.numberOfRooms} rooms,{' '}
            {ad.realEstateObject.totalArea}m<sup>2</sup>
          </Title>
          <Title level={4}>
            {ad.price} {ad.priceCurrency}
          </Title>
          <Text ellipsis={{ rows: 4 }} style={{ color: 'var(--grey-dark' }}>
            {ad.description}
          </Text>
          <StyledSpace>
            {/* Показываем кнопки только при наведении мыши */}
            {isHovered && (
              <>
                <Button
                  type="secondary"
                  onClick={() => navigate(`/admin/announcement/${ad.id}`)}
                  icon={<Icon name="arrowUp" style={{ fontSize: '12px' }} />}
                />
                <Button
                  type="secondary"
                  onClick={() => navigate(`/admin/edit-announcement/${ad.id}`)}
                  icon={<EditOutlined />}
                />
                <Button
                  type="secondary"
                  icon={
                    <DeleteOutlined onClick={() => onDeleteItem?.(ad.id)} />
                  }
                />
              </>
            )}
          </StyledSpace>
        </Space>
      </Col>
    </Row>
  );
};

export default AdItem;
