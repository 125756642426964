import { useEffect } from 'react';
import { Content } from 'antd/es/layout/layout';
import Footer from './footer';
import { StyledHeader, StyledLayout } from './style';

export type ThemeColors = 'secondary';

type LayoutProps = {
  children: React.ReactNode;
  head?: React.ReactNode;
  title?: string;
  theme?: ThemeColors;
};

const Layout = ({ children, head, theme, title }: LayoutProps) => {
  useEffect(() => {
    document.title = title || 'React page';
  }, [title]);

  return (
    <StyledLayout>
      <StyledHeader theme={theme}>{head}</StyledHeader>
      <Content>{children}</Content>
      <Footer />
    </StyledLayout>
  );
};
export default Layout;
