import React from 'react';
import { useLoadScript, Libraries } from '@react-google-maps/api';
import { Map } from './map';
import { AutocompleteAddress, LatLng } from '../ad-form';
const libraries = ['places'] as Libraries;

interface GoogleMapPlacesProps {
  handleAutocomplete?: (address: AutocompleteAddress) => void;
  handleMapClick?: (address: AutocompleteAddress) => void;
  currentLatLng: LatLng;
  showAutocomplete?: boolean;
}
const GoogleMapPlaces = ({
  handleAutocomplete,
  handleMapClick,
  currentLatLng,
  showAutocomplete = true,
}: GoogleMapPlacesProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    libraries,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <Map
      handleAutocomplete={handleAutocomplete}
      handleMapClick={handleMapClick}
      currentLatLng={currentLatLng}
      showAutoComplete={showAutocomplete}
    />
  );
};

export default React.memo(GoogleMapPlaces);
