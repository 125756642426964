import { Announcement } from '../../types/response/announcement';

export const getInitialValues = (values: Announcement) => {
  const {
    //eslint-disable-next-line
    realEstateObject,
    //eslint-disable-next-line
    photos,
    pledgeCurrency,
    pledgeToOwner,
    price,
    priceCurrency,
    ...initialValues
  } = values;

  const fileList = values.photos.map((url, index) => {
    return {
      uid: index,
      name: `image ${index}`,
      status: 'done',
      url: url,
    };
  });

  return {
    ...initialValues,
    ...values.realEstateObject,
    photos: fileList,
    price: { value: price, currency: priceCurrency },
    pledgeToOwner: { value: pledgeToOwner, currency: pledgeCurrency },
  };
};
