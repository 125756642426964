import React from 'react';
import useTranslate from '../../hooks/use-translate';
import { StyledSpace } from './style';
import MenuListVertical from '../menu-list-vertical';
import { Icon, Text } from '../../components-library';

function LocaleSelect() {
  const { lang, setLang } = useTranslate();

  const options = [
    { value: 'en', label: 'English', onClick: () => setLang('en') },
    { value: 'ru', label: 'Русский', onClick: () => setLang('ru') },
    { value: 'id', label: 'Indonesian', onClick: () => setLang('id') },
  ];
  const selected = options.find((item) => item.value === lang);

  return (
    <MenuListVertical items={options}>
      <StyledSpace size={10}>
        <Icon name="lang" color="var(--white)" style={{ fontSize: '24px' }} />
        <Text span>{selected?.label}</Text>
      </StyledSpace>
    </MenuListVertical>
  );
}

export default React.memo(LocaleSelect);
