import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Col, Row } from 'antd';
import useTranslate from '../../hooks/use-translate';
import villoLogo from '../../assets/logo_no_text.png';
import buyVillaImage from '../../assets/image_1-min.png';
import rentVillaImage from '../../assets/image_2-min.png';
import RadioGroup from '../../components/radio-group';
import MainFilterCard from '../../components/main-filter-card';
import FilterGroup from '../../components/filter-group';
import {
  FilterCardWrapper,
  FilterWrapper,
  Header,
  StyledTitle,
  Wrapper,
} from './style';
import { Button, Icon } from '../../components-library';
import buildQuery from '../../utils/build-query';
import { AnnouncementFilter } from '../../app/announcement-list';
import { ButtonsContainer } from '../../components-library/styled';

const radioFilterMap = {
  SALE: 'dealType',
  RENT: 'dealType',
  COMMERCIAL: 'realEstateType',
  DAILY_RENT: 'rentType',
};

const PropertyFilter = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [filter, setFilter] = useState<AnnouncementFilter>({});
  const [roomsFilter, setRoomsFilter] = useState<number | null>(null);

  const callbacks = {
    handleClickBuyFilter: () => {
      const roomsParam = roomsFilter ? `&rooms=${roomsFilter}` : '';
      const searchParams = `dealType=SALE&realEstateObjectTypes=VILLA${roomsParam}`;
      navigate(`admin/announcement-list?${searchParams}`);
    },
    handleClickRentFilter: () => {
      const roomsParam = roomsFilter ? `&rooms=${roomsFilter}` : '';
      const searchParams = `dealType=RENT&realEstateObjectTypes=VILLA${roomsParam}`;
      navigate(`admin/announcement-list?${searchParams}`);
    },
    handleSearchClick: () => {
      const query = buildQuery({ filter });
      navigate(`./admin/announcement-list/?${query}`);
    },
    handleChangeFilter: (
      value: string | number | number[] | string[],
      filterName?: string,
    ) => {
      if (!filterName && typeof value === 'string') {
        const topFilter = {
          dealType: undefined,
          realEstateType: undefined,
          rentType: undefined,
        };
        // в верхней панельке применяем только один из выбранных фильтров
        topFilter[radioFilterMap[value]] = value;
        setFilter((prev) => ({ ...prev, ...topFilter }));
      } else if (filterName) {
        setFilter((prev) => ({ ...prev, [filterName]: value }));
      }
    },
  };

  return (
    <>
      <Wrapper>
        <Header>
          <img src={villoLogo} alt="logo" style={{ width: '33px' }} />
          <StyledTitle level={2}>{t('filter.propertySearch')}</StyledTitle>
        </Header>
        <FilterWrapper>
          <RadioGroup
            onChange={callbacks.handleChangeFilter}
            items={[
              {
                value: 'SALE',
                label: t('filter.buy'),
              },
              {
                value: 'RENT',
                label: t('filter.rent'),
              },
              {
                value: 'COMMERCIAL',
                label: t('filter.commercial'),
              },
              {
                value: 'DAILY_RENT',
                label: t('filter.daily'),
              },
            ]}
          />
          <Row gutter={15}>
            <Col>
              <FilterGroup
                selected={filter}
                onChange={callbacks.handleChangeFilter}
              />
            </Col>
            <ButtonsContainer>
              <Button
                type="secondary"
                icon={<Icon name="search" style={{ fontSize: '24px' }} />}
                onClick={callbacks.handleSearchClick}
              />
              <Button
                type="secondary"
                icon={
                  <Icon
                    name="map"
                    style={{
                      fontSize: '24px',
                    }}
                  />
                }
              >
                {t('filter.showOnMap')}
              </Button>
            </ButtonsContainer>
          </Row>
        </FilterWrapper>
      </Wrapper>
      <FilterCardWrapper>
        <MainFilterCard
          title={t('filter.buyVilla')}
          bcgImage={buyVillaImage}
          handleChangeRoomFilter={setRoomsFilter}
          handleClickLink={callbacks.handleClickBuyFilter}
        />
        <MainFilterCard
          title={t('filter.rentVilla')}
          bcgImage={rentVillaImage}
          handleChangeRoomFilter={setRoomsFilter}
          handleClickLink={callbacks.handleClickRentFilter}
        />
      </FilterCardWrapper>
    </>
  );
};

export default PropertyFilter;
