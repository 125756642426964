import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../assets/logo-no-text.svg';
import { InnerWrapper, Wrapper } from './style';

const StyledLogo = styled(Logo)`
  path {
    fill: var(--blue);
  }
`;

type LayoutLoginFormProps = {
  title: string;
  children: React.ReactNode;
};

const LayoutLoginForm = (props: LayoutLoginFormProps) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <StyledLogo />
        <h3>{props.title}</h3>
        {props.children}
      </InnerWrapper>
    </Wrapper>
  );
};

export default LayoutLoginForm;
