import styled from 'styled-components';
import { Checkbox } from 'antd';

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
`;
export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--blue);
    border-color: transparent;
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: var(--blue);
  }
  .ant-checkbox-input:focus {
    border: none;
    outline: none;
  }
  .ant-checkbox-input:hover {
    border: none;
    outline: none;
  }
  .ant-checkbox-inner:hover {
    background-color: var(--blue);
  }
  .ant-checkbox-wrapper:hover {
    background-color: var(--blue);
  }
  .ant-checkbox-wrapper-disabled:hover {
    background-color: var(--blue);
  }
`;
