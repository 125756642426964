import React from 'react';
import { Form, message } from 'antd';
import Layout from '../../components/layout';
import AdForm, { AdFormValue } from '../../components/ad-form';
import useTranslate from '../../hooks/use-translate';
import Header from '../../containers/header';
import { useCreateAnnouncementMutation } from '../../api/announcement';
import useTypedSelector from '../../hooks/use-selector';
import { useNavigate } from 'react-router-dom';

function CreateAnnouncement() {
  const { t } = useTranslate();
  const [form] = Form.useForm<AdFormValue>();
  const navigate = useNavigate();
  const user = useTypedSelector((state) => state.session.user);

  const [createAnnouncement] = useCreateAnnouncementMutation();

  const callbacks = {
    onFinish: async (values) => {
      await createAnnouncement({
        user: { id: user.id, name: user.name, email: user.email },
        ...values,
      })
        .unwrap()
        .then((res) => {
          message.success(t('message.creationSuccess'), 4.5);
          navigate(`/admin/announcement/${res.id}`);
          form.resetFields();
        })
        .catch((error) => {
          console.log(error);

          message.error(error?.data?.message || t('message.creationError'));
        });
    },
    onCancel: () => {
      form.resetFields();
      navigate(-1);
    },
  };

  return (
    <Layout title="Villo create an announcement" head={<Header />}>
      <div className="container">
        <h2 style={{ padding: '48px 0px', fontSize: '24px' }}>
          {t('anno.newAnno')}
        </h2>
        <AdForm
          onFinish={callbacks.onFinish}
          onCancel={callbacks.onCancel}
          form={form}
          initialValues={{
            announcementType: 'OWNER',
            dealType: 'RENT',
            rentType: 'LONG_TERM_RENT',
            realEstateType: 'RESIDENTIAL',
          }}
          buttonSaveTitle={t('anno.publish')}
        />
      </div>
    </Layout>
  );
}

export default CreateAnnouncement;
