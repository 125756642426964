import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useTypedSelector from '../../hooks/use-selector';

type ProtectedProps = {
  children: React.ReactNode;
  redirect: string;
};

function Protected({ children, redirect }: ProtectedProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuth = useTypedSelector((state) => state.session.isAuth);
  const isWaiting = useTypedSelector((state) => state.session.isWaiting);

  useEffect(() => {
    if (!isAuth && !isWaiting) {
      navigate(redirect, { state: { back: location.pathname } });
    }
  }, [isAuth, isWaiting, navigate, location.pathname, redirect]);

  return !isAuth || isWaiting ? (
    <div>Проверка доступа...</div>
  ) : (
    <>{children}</>
  );
}

export default React.memo(Protected);
