import { combineReducers, configureStore } from '@reduxjs/toolkit';
import localesSlice from './locale';
import { announcementApi } from '../api/announcement';
import { usersApi } from '../api/users';
import sessionSlice from './session';

const reducers = {
  [announcementApi.reducerPath]: announcementApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  locales: localesSlice.reducer,
  session: sessionSlice.reducer,
};

const rootReducer = combineReducers(reducers);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      announcementApi.middleware,
      usersApi.middleware,
    ),
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
