import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const theme = {
  primary: 'var(--blue)',
  secondary: 'var(--neon-green)',
  black: 'var(--grey-black)',
  white: 'var(--white)',

  mainText: ['14px', '140%'],
};

function ThemeProvider({ children }) {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
}

export { ThemeProvider };
