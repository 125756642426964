import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/response/users';

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    user: {} as User,
    isAuth: false,
    isWaiting: true,
  },
  reducers: {
    signIn(state, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
      state.isAuth = true;
      state.isWaiting = false;
    },
    signOut(state) {
      state.user = {} as User;
      state.isAuth = false;
      state.isWaiting = false;
    },
    remind(state, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
      state.isAuth = true;
      state.isWaiting = false;
    },
    setWaiting(state, action: PayloadAction<boolean>) {
      state.isWaiting = action.payload;
    },
  },
});

export default sessionSlice;
