import styled from 'styled-components';
import bcgLine from '../../assets/bcg-lines.svg';
import { Row } from 'antd';
import { Title } from '../../components-library';

export const Wrapper = styled.div`
  color: var(--white);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  height: 544px;
  width: 100%;
  background: linear-gradient(#0097ff, #00caff);
  overflow: hidden;
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    background: url(${bcgLine});
    width: 500%;
    height: 500%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const Header = styled.div`
  margin-top: 70px;
  text-align: center;
  z-index: 1;
`;

export const StyledTitle = styled(Title)`
  color: var(--white);
`;

export const FilterWrapper = styled.div`
  margin: 58px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  z-index: 1;
`;

export const FilterCardWrapper = styled(Row)`
  justify-content: center;
  gap: 24px;
  position: relative;
  top: -130px;
`;
