import { Radio, RadioChangeEvent } from 'antd';
import { StyledRadioGroup } from './style';

type RadioGroupProps = {
  onChange: (value: number, label?: string) => void;
  items: { value: number | string; label: string }[];
};

const RadioGroup = (props: RadioGroupProps) => {
  const onChangeFilter = (e: RadioChangeEvent) => {
    const value = e.target.value;

    props.onChange(value);
  };

  return (
    <StyledRadioGroup onChange={onChangeFilter}>
      {props.items.map((item) => {
        return (
          <Radio.Button key={item.value} value={item.value}>
            {item.label}
          </Radio.Button>
        );
      })}
    </StyledRadioGroup>
  );
};
export default RadioGroup;
