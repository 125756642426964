import { Pagination, Row, Spin, PaginationProps, message } from 'antd';
import Layout from '../../components/layout';
import {
  useDeleteAnnouncementMutation,
  useGetAnnouncementsQuery,
} from '../../api/announcement';
import Header from '../../containers/header';
import AdList from '../../components/ad-list';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import parseParams from '../../utils/parse-params';
import stringifyParams from '../../utils/stringify-params';
import {
  DealType,
  RealEstateObjectType,
  RealEstateType,
  RentType,
} from '../../types/enums';
import makeFilterArray from '../../utils/make-filter-array';
import FilterGroup from '../../components/filter-group';
import { Button, Icon } from '../../components-library';
import RadioGroup from '../../components/radio-group';
import useTranslate from '../../hooks/use-translate';
import { ButtonsContainer } from '../../components-library/styled';

export type AnnouncementFilter = Partial<{
  dealType: DealType;
  rentType: RentType;
  rooms: number[];
  priceMin: number;
  priceMax: number;
  realEstateType: RealEstateType;
  realEstateObjectTypes: RealEstateObjectType[];
  region: string;
}>;

const radioFilterMap = {
  SALE: 'dealType',
  RENT: 'dealType',
  COMMERCIAL: 'realEstateType',
  DAILY_RENT: 'rentType',
};

const PAGE_SIZE = 5;

const AnnouncementSearch = () => {
  const { t } = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const parsedSearchParams = parseParams(searchParams.toString());
  const [page, setPage] = useState((parsedSearchParams.page as number) || 1);

  const [deleteAnnouncement] = useDeleteAnnouncementMutation();

  const [filter, setFilter] = useState<AnnouncementFilter>({
    dealType: (parsedSearchParams.dealType as DealType) || undefined,
    rentType: (parsedSearchParams.rentType as RentType) || undefined,
    rooms: makeFilterArray(parsedSearchParams.rooms as number[]),
    priceMin: parsedSearchParams.priceMin as number,
    priceMax: parsedSearchParams.priceMax as number,
    realEstateType:
      (parsedSearchParams.realEstateType as RealEstateType) || undefined,
    realEstateObjectTypes: makeFilterArray(
      parsedSearchParams.realEstateObjectTypes as RealEstateObjectType[],
    ),
    region: parsedSearchParams.region as string,
  });

  const {
    data,
    isFetching,
    refetch: announcementRefetch,
  } = useGetAnnouncementsQuery(
    {
      pageable: {
        page: page - 1,
        size: PAGE_SIZE,
        sort: [
          {
            property: 'id',
            direction: 'desc',
          },
        ],
      },
      filter: filter,
    },
    { refetchOnMountOrArgChange: true },
  );

  const callbacks = {
    handleChangeFilter: useCallback(
      (value: string | number | number[] | string[], filterName?: string) => {
        if (!filterName && typeof value === 'string') {
          setFilter((prev) => ({ ...prev, [radioFilterMap[value]]: value }));
        } else if (filterName) {
          // дополнительная проверка для селекта комнат, чтобы исключить из фильтра пустой массив
          // т.к. фильтрация на сервере по пустому массиву ничего не находит
          if (filterName === 'rooms') {
            const rooms = value as number[];
            setFilter((prev) => ({
              ...prev,
              [filterName]: rooms.length > 0 ? rooms : undefined,
            }));
          } else {
            setFilter((prev) => ({ ...prev, [filterName]: value }));
          }
        }
        setPage(1);
      },
      [],
    ),
    handleChangePage: useCallback<NonNullable<PaginationProps['onChange']>>(
      (newPage) => {
        setPage(newPage);
      },
      [],
    ),
    handleDeleteAnnouncement: useCallback(
      async (id: string | number) => {
        try {
          await deleteAnnouncement({ id });
          message.destroy();
          message.success('Successfully deleted');
          announcementRefetch();
        } catch (error) {
          message.destroy();
          message.success('Deleting error');
        }
      },
      [announcementRefetch, deleteAnnouncement],
    ),
  };

  useEffect(() => {
    const params = {} as Record<string, string | number | number[] | string[]>;

    if (filter.dealType) params.dealType = filter.dealType;
    if (filter.rentType) params.rentType = filter.rentType;
    if (filter.realEstateType) params.realEstateType = filter.realEstateType;
    if (filter.realEstateObjectTypes)
      params.realEstateObjectTypes = filter.realEstateObjectTypes;
    if (filter.rooms) params.rooms = filter.rooms;
    if (filter.priceMin) params.priceMin = filter.priceMin;
    if (filter.priceMax) params.priceMax = filter.priceMax;

    setSearchParams(stringifyParams(params), { replace: true });
  }, [filter, page, setSearchParams]);

  return (
    <Layout
      title="Villo search announcements"
      theme="secondary"
      head={<Header theme="secondary" />}
    >
      <div className="container">
        <Row style={{ margin: '24px 0' }}>
          <RadioGroup
            onChange={callbacks.handleChangeFilter}
            items={[
              {
                value: 'SALE',
                label: t('filter.buy'),
              },
              {
                value: 'RENT',
                label: t('filter.rent'),
              },
              {
                value: 'COMMERCIAL',
                label: t('filter.commercial'),
              },
              {
                value: 'DAILY_RENT',
                label: t('filter.daily'),
              },
            ]}
          />
        </Row>
        <Row
          justify={'space-between'}
          wrap={false}
          style={{ margin: '24px 0 48px' }}
        >
          <FilterGroup
            onChange={callbacks.handleChangeFilter}
            selected={filter}
          />
          <ButtonsContainer>
            <Button
              style={{ backgroundColor: 'var(--grey-dark)' }}
              type="primary"
              icon={<Icon name="settings" color="white" />}
            />
            <Button
              type="primary"
              icon={<Icon name="search" color="white" />}
            />
          </ButtonsContainer>
        </Row>
        <Spin spinning={isFetching}>
          <AdList
            list={data?.content || []}
            onDeleteItem={callbacks.handleDeleteAnnouncement}
          />
        </Spin>
        <Row justify="center" style={{ margin: '40px 0' }}>
          <Pagination
            defaultCurrent={page}
            total={data?.totalElements}
            pageSize={PAGE_SIZE}
            onChange={callbacks.handleChangePage}
          />
        </Row>
      </div>
    </Layout>
  );
};

export default AnnouncementSearch;
