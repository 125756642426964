import AntIcon from '@ant-design/icons';
import React from 'react';
import * as icons from '../../../assets/icons';
import styled from 'styled-components';

type Icons = typeof icons;

export type IconNames = keyof Icons;

export type IconProps = Omit<Parameters<typeof AntIcon>[0], 'name'> & {
  name: IconNames;
};

function getIcon<T extends IconNames>(name: T) {
  const icon = icons[name];
  if (!icon) {
    console.warn(name, 'is wrong icon name');
  }

  return icon as IconProps['component'];
}

const StyledIcon = styled(AntIcon)`
  &.anticon {
    svg path {
      fill: ${(props) => props.color || 'var(--black)'};
      stroke: ${(props) => props.color || 'var(--black)'};
      stroke-width: 0;
    }
  }
`;

export function Icon({ name, ...props }: IconProps) {
  return <StyledIcon component={getIcon(name)} {...props} />;
}
