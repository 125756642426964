import React from 'react';
import styled from 'styled-components';
import { Menu as AntMenu, MenuProps as AntdMenuProps } from 'antd';

const StyledMenu = styled(AntMenu)<{ theme?: MenuProps['theme'] }>`
  margin: 10px 0 0 40px;
  border: 0;
  &.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after,
  &.ant-menu-blue.ant-menu-horizontal > .ant-menu-item::after {
    bottom: 10px;
  }

  ${(props) =>
    props.theme === 'secondary'
      ? `
  &.ant-menu {
    background-color: var(--blue);
    a {
      color: var(--white);
      &:hover {
        color: var(--white);
      }
    }
    .ant-menu-item:hover::after {
      border-bottom-color: var(--white);
    }
  }
`
      : ``}
`;

export interface MenuProps extends Omit<AntdMenuProps, 'theme'> {
  theme?: AntdMenuProps['theme'] | 'secondary';
}

export function Menu(props: MenuProps) {
  return <StyledMenu {...(props as AntdMenuProps)} />;
}
