import { Col, Row, Space, Spin } from 'antd';
import Layout from '../../components/layout';
import { useGetAnnouncementByIdQuery } from '../../api/announcement';
import Header from '../../containers/header';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Icon, Text, Title } from '../../components-library';
import RadioGroup from '../../components/radio-group';
import useTranslate from '../../hooks/use-translate';
import FilterGroup from '../../components/filter-group';
import { ButtonsContainer } from '../../components-library/styled';
import styled from 'styled-components';
import React, { useState } from 'react';
import { IconNames } from '../../components-library/core-components/icon/Icon';
import { AnnouncementFilter } from '../announcement-list';
import buildQuery from '../../utils/build-query';
import GoogleMapPlaces from '../../components/google-map';
import capitalizeWord from '../../utils/capitalize-word';

const StyledButton = styled(Button)`
  color: ${(props) => props.color || 'var(--grey-dark)'} !important;
  cursor: pointer;
  svg {
    margin-right: 12px;
  }
  &.ant-btn:hover:not(:disabled):not(.ant-btn-text) {
    & .anticon {
      svg path {
        fill: var(--grey-dark);
        stroke: var(--grey-dark);
        stroke-width: 0;
      }
    }
  }
`;
const BigImage = styled.img`
  width: 564px;
  height: 378px;
  border-radius: 16px;
`;

const SmallImage = styled.img`
  width: 182px;
  height: 182px;
  border-radius: 16px;
`;

const StyledCard = styled.div`
  padding: 25px;
  border-radius: 10px;
  width: 368px;
  height: 194px;
  background-color: var(--grey-light);
`;

const StyledPrice = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  display: inline-block;
  margin-bottom: 8px;
  color: var(--black);
`;

const AdditiveWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledAdditive = styled.span`
  color: var(--grey-dark);
  &:not(:last-child)::after {
    content: '•';
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const FacilitiesWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledFacility = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  svg {
    width: 44px;
    height: 44px;
  }
  div {
    margin-bottom: 0;
  }
`;

const StyledDescription = styled(Text)`
  max-width: 758px;
  margin-bottom: 40px !important;
`;

const GeneralWrapper = styled.div`
  padding: 40px 0;
  max-width: 757px;
  border-top: 1px solid var(--grey-medium);
  border-bottom: 1px solid var(--grey-medium);
`;

const MapWrapper = styled.div`
  padding: 40px 0;
  border-bottom: 1px solid var(--grey-medium);
`;

const StyledKey = styled.div`
  font-size: 12px;
  color: var(--grey-dark);
`;

const StyledValue = styled(Text)`
  margin-bottom: 2px !important;
`;

const radioFilterMap = {
  SALE: 'dealType',
  RENT: 'dealType',
  COMMERCIAL: 'realEstateType',
  DAILY_RENT: 'rentType',
};

const Announcement = () => {
  const { id: announcementId } = useParams();
  const { data, isFetching } = useGetAnnouncementByIdQuery(
    { id: announcementId as string },
    { skip: !announcementId },
  );
  console.log(data);
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [showNumber, setShowNumber] = useState(false);
  const [filter, setFilter] = useState<AnnouncementFilter>({});

  const callbacks = {
    handleSearchClick: () => {
      const query = buildQuery({ filter });
      navigate(`/admin/announcement-list/?${query}`);
    },
    handleChangeFilter: (
      value: string | number | number[] | string[],
      filterName?: string,
    ) => {
      if (!filterName && typeof value === 'string') {
        const topFilter = {
          dealType: undefined,
          realEstateType: undefined,
          rentType: undefined,
        };
        topFilter[radioFilterMap[value]] = value;
        setFilter((prev) => ({ ...prev, ...topFilter }));
      } else if (filterName) {
        setFilter((prev) => ({ ...prev, [filterName]: value }));
      }
    },
  };

  if (isFetching) {
    return (
      <Layout
        title="Villo search announcements"
        theme="secondary"
        head={<Header theme="secondary" />}
      >
        <div className="container">
          <Spin
            size="large"
            style={{ display: 'block', margin: '100px auto' }}
          />
        </div>
      </Layout>
    );
  }

  return (
    <Layout
      title="Villo search announcements"
      theme="secondary"
      head={<Header theme="secondary" />}
    >
      <div className="container">
        <Row style={{ margin: '24px 0' }}>
          <RadioGroup
            onChange={callbacks.handleChangeFilter}
            items={[
              {
                value: 'SALE',
                label: t('filter.buy'),
              },
              {
                value: 'RENT',
                label: t('filter.rent'),
              },
              {
                value: 'COMMERCIAL',
                label: t('filter.commercial'),
              },
              {
                value: 'DAILY_RENT',
                label: t('filter.daily'),
              },
              {
                value: 'FIND_AGENT',
                label: t('menu.findAgent'),
              },
            ]}
          />
        </Row>
        <Row
          justify={'space-between'}
          wrap={false}
          style={{ margin: '24px 0 48px' }}
        >
          <FilterGroup
            onChange={callbacks.handleChangeFilter}
            selected={filter}
          />
          <ButtonsContainer>
            <Button
              style={{ backgroundColor: 'var(--grey-dark)' }}
              type="secondary"
              icon={<Icon name="settings" color="white" />}
            />
            <Button
              onClick={callbacks.handleSearchClick}
              type="primary"
              icon={<Icon name="search" color={'white'} />}
            />
          </ButtonsContainer>
        </Row>
        <Row justify={'space-between'} align={'middle'}>
          <Title level={3}>
            {data?.realEstateObject.numberOfRooms === (1 as number)
              ? '1-room, '
              : `${data?.realEstateObject.numberOfRooms}-rooms, `}
            {`${data?.realEstateObject.totalArea} m² `}
            {capitalizeWord(data?.realEstateObject.realEstateObjectType) +
              ' in '}

            {data?.realEstateObject.address.city}
          </Title>
          <Space.Compact>
            <StyledButton
              icon={<Icon name={'share'} color={'var(--grey-dark)'} />}
            >
              Share
            </StyledButton>
            <StyledButton
              icon={<Icon name={'favorite'} color={'var(--grey-dark)'} />}
            >
              Favorite
            </StyledButton>
            <StyledButton
              icon={<Icon name={'complain'} color={'var(--grey-dark)'} />}
            >
              Complain
            </StyledButton>
          </Space.Compact>
        </Row>
        <Row gutter={32} style={{ margin: '16px 0 45px 0' }}>
          <ButtonsContainer>
            <Button
              icon={<Icon name={'location'} color={'var(--black)'} />}
              style={{ padding: '0', marginLeft: '12px', cursor: 'initial' }}
            >
              Indonesia, {data?.realEstateObject.address.city}
            </Button>
            <Button
              style={{ textDecoration: 'underline', color: 'var(--blue)' }}
            >
              Show on map
            </Button>
          </ButtonsContainer>
        </Row>
        <Row
          justify="space-between"
          style={{ marginBottom: '14px' }}
          align="middle"
        >
          <Row gutter={16}>
            <Col>
              <BigImage src={data?.photos[0]} alt="Big Image" />
            </Col>
            <Col>
              <Row gutter={[16, 11]} style={{ maxWidth: '192px' }}>
                <Col>
                  <SmallImage src={data?.photos[0]} alt="Small Image 1" />
                </Col>
                <Col>
                  <SmallImage src={data?.photos[0]} alt="Small Image 2" />
                </Col>
              </Row>
            </Col>
          </Row>
          <StyledCard>
            <StyledPrice>
              {data?.price} <Icon name={data?.priceCurrency as IconNames} />
            </StyledPrice>
            <Text style={{ color: 'var(--grey-dark)' }}>208 000 Rp/m²</Text>
            <Button
              onClick={() => setShowNumber(true)}
              style={{ width: '100%' }}
              type="primary"
            >
              {showNumber ? data?.contact : 'Show Number'}
            </Button>
          </StyledCard>
        </Row>
        <AdditiveWrapper>
          <StyledAdditive>2 bedroom</StyledAdditive>
          <StyledAdditive>2 bed</StyledAdditive>
          <StyledAdditive>2 baths</StyledAdditive>
        </AdditiveWrapper>
        <FacilitiesWrapper>
          {data?.realEstateObject.pool && (
            <StyledFacility>
              <Icon width={'44px'} height={'44px'} name="pool" />
              <Text>Pool</Text>
            </StyledFacility>
          )}
        </FacilitiesWrapper>
        <StyledDescription>{data?.description}</StyledDescription>
        <GeneralWrapper>
          <Title level={4} style={{ marginBottom: '24px' }}>
            General
          </Title>
          <Row align="middle" style={{ margin: '0' }} gutter={24} wrap={false}>
            <Row gutter={[14, 14]} align="middle">
              <Col xs={12} sm={6}>
                <StyledKey>Object type</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {capitalizeWord(data?.realEstateObject.realEstateObjectType)}
                </StyledValue>
              </Col>
              <Col xs={12} sm={6}>
                <StyledKey>Rooms space</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {data?.realEstateObject.livingSpace || 100} m²
                </StyledValue>
              </Col>
              <Col xs={12} sm={6}>
                <StyledKey>With childrens</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {data?.realEstateObject.possibleWithChildren ? 'Yes' : 'No'}
                </StyledValue>
              </Col>
              <Col xs={12} sm={6}>
                <StyledKey>With animals</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {data?.realEstateObject.possibleWithAnimals ? 'Yes' : 'No'}
                </StyledValue>
              </Col>
            </Row>
            <Row gutter={[14, 14]} align="middle">
              <Col xs={12} sm={6}>
                <StyledKey>Bathroom</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {data?.realEstateObject.bathtub ? '1' : 'No'}
                </StyledValue>
              </Col>
              <Col xs={12} sm={6}>
                <StyledKey>Floors</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {data?.realEstateObject.floorsInTheHouse}
                </StyledValue>
              </Col>
              <Col xs={12} sm={6}>
                <StyledKey>Rooms</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {data?.realEstateObject.numberOfRooms}
                </StyledValue>
              </Col>
              <Col xs={12} sm={6}>
                <StyledKey>Estate Type</StyledKey>
              </Col>
              <Col xs={12} sm={14}>
                <StyledValue>
                  {capitalizeWord(data?.realEstateObject.realEstateType)}
                </StyledValue>
              </Col>
            </Row>
          </Row>
        </GeneralWrapper>
        <MapWrapper>
          <Title level={4}>Map</Title>
          <GoogleMapPlaces
            showAutocomplete={false}
            currentLatLng={{
              lat: data?.realEstateObject.address.latitude as number,
              lng: data?.realEstateObject.address.longitude as number,
            }}
          />
        </MapWrapper>
      </div>
    </Layout>
  );
};

export default Announcement;
