import styled from 'styled-components';
import { Typography } from 'antd';

export const Text = styled(Typography.Paragraph).attrs<{ span?: boolean }>(
  (props) => ({
    as: props.span ? Typography.Text : Typography.Paragraph,
  }),
)<{ sm?: boolean; bold?: boolean; p?: boolean; title?: boolean }>`
  font-size: ${(props) => (props.sm ? '14px' : '16px')};
  line-height: ${(props) => (props.sm ? '1.375' : '1.5')};
  font-weight: ${(props) => (props.bold || props.title ? '600' : '400')};

  &.caption {
    font-size: 12px;
    line-height: 1.125;
    font-weight: 400;
  }
  &.overline {
    font-size: 12px;
    line-height: 1.125;
    font-weight: 500;
    word-spacing: 0.2;
  }
`;
