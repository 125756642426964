import React, { useState } from 'react';
import { Form, message, Spin } from 'antd';
import Layout from '../../components/layout';
import AdForm, { AdFormValue } from '../../components/ad-form';
import useTranslate from '../../hooks/use-translate';
import Header from '../../containers/header';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetAnnouncementByIdQuery,
  useUpdateAnnouncementMutation,
} from '../../api/announcement';
import { getInitialValues } from './get-initial-values';
import { Title } from '../../components-library';

function EditAnnouncement() {
  const { t } = useTranslate();
  const { id: announcementId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<AdFormValue>();
  const [isWaiting, setIsWaiting] = useState(false);

  const { data: announcement, isFetching } = useGetAnnouncementByIdQuery(
    { id: announcementId as string },
    { skip: !announcementId, refetchOnMountOrArgChange: true },
  );
  const [updateAnnouncement] = useUpdateAnnouncementMutation();

  const isLatLngExist =
    !!announcement?.realEstateObject.address.latitude &&
    !!announcement?.realEstateObject.address.latitude;

  const callbacks = {
    onFinish: async (values) => {
      setIsWaiting(true);
      await updateAnnouncement({
        id: announcementId as string,
        patch: values,
      })
        .unwrap()
        .then(() => {
          message.success(t('message.updateSuccess'), 4.5);
          navigate(`/admin/announcement/${announcementId}`);
          form.resetFields;
        })
        .catch((error) => {
          message.error(error?.data?.message || t('message.updateError'));
        });
      setIsWaiting(false);
    },
    onCancel: () => {
      form.resetFields;
      navigate(`/admin/announcement-list`);
    },
  };

  return (
    <Layout title="Villo edit an announcement" head={<Header />}>
      <div className="container">
        <Title level={3} style={{ padding: '32px 0px' }}>
          Edit Announcement (id: {announcementId})
        </Title>
        <Spin spinning={isFetching || isWaiting}>
          <AdForm
            onFinish={callbacks.onFinish}
            onCancel={callbacks.onCancel}
            form={form}
            initialValues={announcement && getInitialValues(announcement)}
            isChangeAnnouncement
            buttonSaveTitle={t('save')}
            currentLatLng={
              isLatLngExist
                ? {
                    lat: announcement?.realEstateObject.address.latitude,
                    lng: announcement?.realEstateObject.address.longitude,
                  }
                : undefined
            }
          />
        </Spin>
      </div>
    </Layout>
  );
}

export default EditAnnouncement;
