import { Carousel, Image, Space } from 'antd';
import styled from 'styled-components';
import { Text } from '../../components-library';

export const StyledCarousel = styled(Carousel)`
  /* Стили точек слайдера */
  .slick-dots li {
    width: 8px;
    button {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: rgba(255, 255, 255, 0.65);
    }
  }
  .slick-dots li.slick-active {
    width: 8px;
    button {
      border-radius: 100%;
      background: #fff;
    }
  }

  /* Стили стрелок */
  .slick-arrow {
    /* Изменения: увеличен размер и изменен цвет фона */
    margin-top: -20px;
    z-index: 1;
    width: 40px;
    font-size: 100%;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(208, 208, 208);
    opacity: 0.8;
    transition:
      opacity 0.3s,
      background-color 0.3s;
  }
  .slick-arrow:before {
    content: none;
  }
  .slick-arrow:hover {
    /* Изменение: уменьшен прозрачность при наведении */
    opacity: 0.7;
    background-color: rgb(188, 188, 188);
  }
  .slick-prev {
    left: 15px;
  }
  .slick-next {
    right: 15px;
  }
`;

interface StyledArrowProps {
  rotated?: boolean;
  isHovered?: boolean;
}

export const StyledArrow = styled.div<StyledArrowProps>`
  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    /* Изменение: убрано свойство "color", оно здесь не нужно */
    svg {
      transform: rotate(${(props) => (props.rotated ? '180deg' : '0deg')});
    }
  }
`;

export const StyledImage = styled(Image)`
  /* Изменение: добавлено свойство "object-fit" */
  &.ant-image-img {
    height: 270px;
    object-fit: cover; /* Изображение будет заполнено внутри блока с сохранением соотношения сторон */
    border-radius: 10px;
  }
`;

export const StyledText = styled(Text)`
  max-height: 96px;
  overflow: hidden;
`;

export const StyledSpace = styled(Space)`
  position: absolute;
  top: 0;
  right: 0;
`;
