import { useCallback } from 'react';
import useTypedSelector from './use-selector';
import { useDispatch } from 'react-redux';
import localeSlice from '../store/locale';
import { Currency } from '../types/enums';

export default function useCurrency() {
  const dispatch = useDispatch();

  const currency = useTypedSelector((state) => state.locales.currency);

  const setCurrency = useCallback(
    (currency: Currency) => dispatch(localeSlice.actions.setCurrency(currency)),
    [dispatch],
  );

  return { currency, setCurrency } as const;
}
