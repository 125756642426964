import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: var(--dark-blue);
  color: var(--white);
  padding-bottom: 32px;
  margin-top: 32px;
`;

export const LinkList = styled.ul`
  list-style-type: none;
  > li:not(:last-child) {
    margin-bottom: 10px;
  }
`;
export const StyledLink = styled(Link)`
  color: var(--white);
  line-height: 1.5;
  font-weight: 400;
  &:hover {
    border-bottom: 1px solid var(--white);
    color: var(--white);
  }
`;
