import styled from 'styled-components';
import { Select } from '../../components-library';
import { Input } from 'antd';
import { RoomSelect } from '../room-select';

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    &.ant-select-selector {
      background-color: var(--grey-light);
      padding: 0 5px;
    }
  }

  .ant-select-item-option-state {
    display: none;
  }

  .ant-select-dropdown {
    width: 175px !important;
    min-width: 175px !important;
  }

  .ant-select-selection-overflow {
    gap: 4px;
    flex-wrap: nowrap;
  }

  .ant-select-selection-item {
    background: none;
    margin-inline: 0;
    padding-inline-end: 0;
    overflow: hidden;
  }

  .ant-select-selection-item-remove {
    display: none;
  }

  .ant-select-selection-item {
    max-width: 100%;
  }
`;

export const StyledRoomSelect = styled(RoomSelect)`
  .ant-select-selector {
    &.ant-select-selector {
      background-color: var(--grey-light);
    }
  }
`;

export const StyledInput = styled(Input)`
  max-width: 437px;
  min-width: 351px;
  border: none;
  background-color: var(--grey-light);
  &.ant-input:focus {
    box-shadow: none;
  }
`;
