import { Upload } from 'antd';
import styled from 'styled-components';

export const TextUpload = styled.div`
  padding: 8px 24px;
  margin-top: 12px;
  display: inline-block;
  color: #fff;
  background-color: grey;
  border-radius: 5px;
`;

export const StyledUpload = styled(Upload.Dragger)`
  .ant-upload-list {
    margin-top: 16px;
  }
`;
