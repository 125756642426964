import { getDetails, getZipCode } from 'use-places-autocomplete';
import { LatLng } from '../components/ad-form';

export async function getGoogleMapAddress(latLng: LatLng) {
  const geocoder = new window.google.maps.Geocoder();
  const address = await geocoder.geocode({ location: latLng });

  const details = await getDetails({
    placeId: address.results[0].place_id,
    fields: ['url'],
  });
  const url = typeof details !== 'string' ? details.url || '' : '';
  const postIndex = getZipCode(address.results[0], false);
  return {
    address: address.results[0].formatted_address,
    postIndex,
    url,
    coords: latLng,
  };
}
