import Layout from '../../components/layout';
import Header from '../../containers/header';
import PropertyFilter from '../../containers/property-filter';

const Home = () => {
  return (
    <>
      <Layout title="Villo" head={<Header />}>
        <PropertyFilter />
      </Layout>
    </>
  );
};

export default Home;
