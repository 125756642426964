import React from 'react';
import styled from 'styled-components';
import { Button as AntButton, ButtonProps as AntdButtonProps } from 'antd';

const StyledButton = styled(AntButton).attrs<ButtonProps>((props) => ({
  disabled: props.blocked,
}))<ButtonProps>`
  font-family: Kanit, serif;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  padding: 12px 24px;
  cursor: ${({ blocked }) => (blocked ? 'not-allowed' : 'pointer')};
  line-height: 24px;
  height: auto;
  opacity: ${(props) => (props.blocked ? '50%' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  border: none;
  box-shadow: none;
  transition:
    background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    fill 0.12s cubic-bezier(0.645, 0.045, 0.355, 1),
    stroke 0.12s cubic-bezier(0.645, 0.045, 0.355, 1);

  & .anticon {
    font-size: 24px;
    & + span {
      margin-left: 12px;
    }
  }

  &.ant-btn:hover:not(:disabled):not(.ant-btn-text) {
    & .anticon {
      svg path {
        fill: var(--white);
        stroke: var(--white);
        stroke-width: 0;
      }
    }
  }

  &.ant-btn-primary {
    border-color: var(--blue);
    background-color: var(--blue);
    background: var(--blue);
    color: var(--white);
  }
  &.ant-btn-primary:hover:not(:disabled) {
    background: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--white);
  }

  &.ant-btn-secondary {
    border-color: var(----neon-green);
    background-color: var(----neon-green);
    background: var(--neon-green);
    color: var(--black);
  }
  &.ant-btn-secondary:hover:not(:disabled) {
    background: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--white);
  }

  &.ant-btn.ant-btn-text {
    background: none;
    color: var(--blue);
    &:hover {
      background: none;
    }
  }
  &.ant-btn-text:hover:not(:disabled) {
    border-bottom: 1px solid var(--blue);
  }

  &.ant-btn-icon-only {
    padding: 12px 14px;
    width: 54px;
    height: 48px;
    > span {
      margin: auto;
      transform: none;
    }
  }
  &.ant-btn.ant-btn-icon-only {
    .anticon {
      font-size: 24px;
    }
  }

  &.ant-btn-sm {
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 24px;
  }

  &:disabled {
    pointer-events: none;
    background: var(--grey-medium);
    border-color: var(--grey-medium);
    color: var(--grey-dark);
  }
`;

export interface ButtonProps extends Omit<AntdButtonProps, 'type'> {
  blocked?: boolean;
  type?: AntdButtonProps['type'] | 'secondary';
}

export function Button({ children, ...props }: ButtonProps) {
  return (
    <StyledButton {...(props as AntdButtonProps)}>{children}</StyledButton>
  );
}
