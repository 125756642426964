import styled from 'styled-components';
import { Typography } from 'antd';

export const Title = styled(Typography.Title)`
  h1&,
  h2&,
  h3&,
  h4&,
  h5& {
    font-family: Kanit, serif;
    line-height: 1.5;
    font-weight: 600;
    margin: 0;
  }

  h1& {
    font-size: 64px;
    line-height: 1.25;
    margin: 0;
  }
  h2& {
    font-size: 48px;
    line-height: 1.33;
    margin: 0;
  }
  h3& {
    font-size: 32px;
    margin: 0;
  }
  h4& {
    font-size: 24px;
  }
  h5& {
    font-size: 20px;
  }
`;
