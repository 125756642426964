import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider, ThemeConfig } from 'antd';
import ru_RU from 'antd/lib/locale/ru_RU';
import en_US from 'antd/lib/locale/en_US';
import id_ID from 'antd/lib/locale/id_ID';
import useTypedSelector from './hooks/use-selector';
import Protected from './containers/protected';
import { useRemindMutation } from './api/users';
import { useDispatch } from 'react-redux';
import sessionSlice from './store/session';
import { defaultRoutes } from './routes/default-routes';

const antdLocalesMap = {
  ru: ru_RU,
  en: en_US,
  id: id_ID,
};

const theme = {
  token: {
    fontFamily: 'Kanit, sans-serif',
    colorPrimary: 'var(--blue)',
    colorBgLayout: '#fff',
    lineHeight: 1.5,
    fontSize: 16,
  },
  components: {
    Typography: {
      colorTextHeading: 'inherit',
      colorText: 'inherit',
    },
  },
} as ThemeConfig;

function App() {
  const lang = useTypedSelector((state) => state.locales.lang);

  const [remindSession] = useRemindMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    const remind = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const user = await remindSession({ token }).unwrap();
          dispatch(sessionSlice.actions.remind({ user }));
        } catch (error) {
          console.log(error);
          localStorage.removeItem('token');
          dispatch(sessionSlice.actions.setWaiting(false));
        }
      } else {
        dispatch(sessionSlice.actions.setWaiting(false));
      }
    };
    remind();
  }, [remindSession, dispatch]);

  return (
    <ConfigProvider locale={antdLocalesMap[lang]} theme={theme}>
      <Routes>
        {defaultRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.protected ? (
                  <Protected redirect="/login">{route.element}</Protected>
                ) : (
                  route.element
                )
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
