import React from 'react';
import useCurrency from '../../hooks/use-currency';
import useTranslate from '../../hooks/use-translate';
import { StyledSpace } from './style';
import MenuListVertical from '../menu-list-vertical';
import { Icon, Text } from '../../components-library';

function CurrencySelect() {
  const { t } = useTranslate();
  const { currency, setCurrency } = useCurrency();

  const options = [
    {
      value: 'IDR',
      label: t('currency.rupiah'),
      onClick: () => setCurrency('IDR'),
    },
    {
      value: 'USD',
      label: t('currency.dollar'),
      onClick: () => setCurrency('USD'),
    },
    {
      value: 'EUR',
      label: t('currency.euro'),
      onClick: () => setCurrency('EUR'),
    },
    {
      value: 'RUB',
      label: t('currency.ruble'),
      onClick: () => setCurrency('RUB'),
    },
  ];
  const selected = options.find((item) => item.value === currency.code);

  return (
    <MenuListVertical items={options}>
      <StyledSpace size={10}>
        <Icon
          name={currency.code}
          color="var(--white)"
          style={{ fontSize: '24px' }}
        />
        <Text span>{selected?.label}</Text>
      </StyledSpace>
    </MenuListVertical>
  );
}

export default React.memo(CurrencySelect);
