import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';

export const baseQueryWithJWTToken = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem('token');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  });
