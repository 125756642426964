import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const InnerWrapper = styled.div`
  padding: 35px 80px 250px 80px;
  width: 340px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
