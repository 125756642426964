export type Filter = Record<string, string | number | Array<string | number>>;

export type BuildQueryArgs = Partial<{
  page: number;
  filter: Record<string, string | number | (string | number)[]>;
}>;

export default function buildQuery({ page = 1, filter }: BuildQueryArgs) {
  let query = '';

  if (page) {
    query += `page=${page}`;
  }

  if (filter) {
    let filterQuery = '';
    for (const type in filter) {
      if (!filter[type]) continue;
      if (Array.isArray(filter[type])) {
        const value = filter[type] as (number | string)[];
        if (value.length > 0) {
          const query = `&${type}=${value.join(',')}`;
          filterQuery = filterQuery.concat(query);
        }
      } else {
        const query = `&${type}=${filter[type]}`;
        filterQuery += filterQuery.concat(query);
      }
      query += filterQuery;
    }
  }

  return query;
}
