import { useCallback } from 'react';
import translate, { ILocales } from '../utils/translate';
import useTypedSelector from './use-selector';
import { useDispatch } from 'react-redux';
import localeSlice from '../store/locale';

export default function useTranslate() {
  const dispatch = useDispatch();

  const lang = useTypedSelector((state) => state.locales.lang);
  const setLang = useCallback(
    (lang: keyof ILocales) => dispatch(localeSlice.actions.setLang(lang)),
    [dispatch],
  );

  // Функция для локализации текстов
  const t = useCallback(
    (text: string, number?: number) => {
      return translate(lang, text, number);
    },
    [lang],
  );

  return { lang, setLang, t } as const;
}
