import styled from 'styled-components';
import { Select } from '../../components-library';

export const StyledSelect = styled(Select)`
  .ant-select-dropdown {
    padding: 16px 20px;
    .ant-select-item {
      display: flex;
      align-items: center;
      border: 1px solid var(--grey);
      border-radius: 8px;
      width: 41px;
      height: 40px;
      padding: 0;
      &:hover {
        padding: 0;
      }
    }
    .ant-select-item-option-content {
      text-align: center;
    }
  }
  .rc-virtual-list-holder-inner {
    flex-direction: row !important;
    gap: 7px;
    flex-wrap: wrap;
  }
`;
