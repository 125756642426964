import { Card } from 'antd';
import styled from 'styled-components';
import { Button, Title } from '../../components-library';

export const StyledCard = styled(Card)`
  background: none;
  box-shadow: none;
  color: var(--white) !important;
  .ant-card-body {
    position: absolute;
    bottom: 0;
  }
  .ant-card-cover {
    position: relative;
    width: 564px;
    height: 303px;
    border-radius: 20px;
    background-color: var(--light-blue);
    overflow: hidden;
    .main-filter-card-button-link {
      position: absolute;
      width: auto;
      right: 32px;
      top: 32px;
    }
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 32px;
  top: 32px;
`;

export const CardTitle = styled(Title)`
  position: absolute;
  bottom: 102px;
  left: 32px;
  color: var(--white);
`;

export const RoomButtonWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  left: 32px;
  text-transform: lowercase;
  width: auto;
  > *:not(:last-child) {
    margin-right: 14px;
  }
`;
