import { Form, FormProps, Input } from 'antd';

export type LoginFormValues = {
  email: string;
  password: string;
};

const LoginForm = (props: FormProps) => {
  const [form] = Form.useForm<LoginFormValues>(props.form);
  return (
    <Form form={form} onFinish={props.onFinish} layout="vertical">
      <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true }]}>
        <Input type="password" placeholder="Password" />
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
