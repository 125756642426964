import React, { useState } from 'react';
import { Divider, Dropdown } from 'antd';
import { MenuList, StyledLink } from './style';

type MenuListVerticalProps = {
  items: {
    link?: string;
    label?: string;
    divider?: boolean;
    onClick?: () => void;
  }[];
  theme?: string;
  children: React.ReactNode;
};

const MenuListVertical = (props: MenuListVerticalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
      overlayStyle={{ margin: '-10px 0' }}
      dropdownRender={() => {
        return (
          <MenuList direction="vertical">
            {props.items.map((item, i) => {
              if (item?.divider) {
                return <Divider key={i} style={{ margin: '8px 0' }} />;
              }
              return (
                <StyledLink
                  key={i}
                  to={item?.link || '#'}
                  onClick={() => {
                    if (item?.onClick) item.onClick();
                    setOpen(false);
                  }}
                >
                  {item?.label}
                </StyledLink>
              );
            })}
          </MenuList>
        );
      }}
    >
      {props.children}
    </Dropdown>
  );
};
export default MenuListVertical;
