import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { Announcement } from '../types/response/announcement';
import {
  CreateAnnouncementArgs,
  DeleteAnnouncementArgs,
  EditAnnouncementArgs,
  FilterAnnouncementArgs,
  GetAnnouncementByIdArgs,
} from '../types/request/announcement';
import { PaginatedResponse } from '../types/utils';
import { baseQueryWithJWTToken } from './base';

export const announcementApi = createApi({
  reducerPath: 'announcementApi',
  baseQuery: baseQueryWithJWTToken('/api/v1/'),
  endpoints: (build) => ({
    createAnnouncement: build.mutation<Announcement, CreateAnnouncementArgs>({
      query: (patch) => ({
        url: `announcements/`,
        method: 'POST',
        body: patch,
      }),
    }),
    updateAnnouncement: build.mutation<Announcement, EditAnnouncementArgs>({
      query: ({ id, patch }) => ({
        url: `announcements/${id}`,
        method: 'PUT',
        body: patch,
      }),
    }),
    deleteAnnouncement: build.mutation<Announcement, DeleteAnnouncementArgs>({
      query: ({ id }) => ({
        url: `announcements/${id}`,
        method: 'DELETE',
      }),
    }),
    getAnnouncements: build.query<
      PaginatedResponse<Announcement>,
      FilterAnnouncementArgs
    >({
      query: (patch) => ({
        url: 'announcements/search',
        method: 'POST',
        body: patch,
      }),
    }),
    getAnnouncementById: build.query<Announcement, GetAnnouncementByIdArgs>({
      query: ({ id }) => `announcements/${id}`,
    }),
    getCities: build.query<string, null | void>({
      query: () => ({
        url: 'dictionary/cities',
        method: 'POST',
        body: ['ID'],
      }),
    }),
  }),
});

export const {
  useCreateAnnouncementMutation,
  useGetAnnouncementsQuery,
  useGetCitiesQuery,
  useGetAnnouncementByIdQuery,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcementApi;
