import React, { useCallback, useEffect } from 'react';
import { Form, Space } from 'antd';
import useTypedSelector from '../../hooks/use-selector';
import { StyledDropdown, StyledInputNumber, StyledLink } from './style';
import { KeyboardEventHandler, useState } from 'react';
import { Icon } from '../../components-library';
import { debounce } from 'lodash';

type DropdownPriceProps = {
  priceMin?: number;
  priceMax?: number;
  onChange?: (value: PriceFormValue) => void;
  initialValues?: Partial<PriceFormValue>;
  style?: React.CSSProperties;
};

export type PriceFormValue = {
  priceMin: number;
  priceMax: number;
};

function getPriceString(min: number, max: number): string | React.ReactNode {
  let formattedPrice = '';
  const placeholder = 'Price';

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });

  if (!min && !max) {
    return placeholder;
  } else if (!min) {
    formattedPrice = `до ${formatter.format(max)}`;
  } else if (!max) {
    formattedPrice = `от ${formatter.format(min)}`;
  } else {
    formattedPrice = `${formatter.format(min)} — ${formatter.format(max)}`;
  }

  return <span style={{ color: 'var(--black)' }}>{formattedPrice}</span>;
}

const DropdownPrice = (props: DropdownPriceProps) => {
  const [form] = Form.useForm<PriceFormValue>();
  const [isOpen, setIsOpen] = useState(false);
  const priceMin = Form.useWatch('priceMin', form);
  const priceMax = Form.useWatch('priceMax', form);

  const currencySymbol = useTypedSelector(
    (state) => state.locales.currency.symbol,
  );
  // eslint-disable-next-line
  const onChange = useCallback(
    debounce((value: PriceFormValue) => {
      props.onChange?.(value);
    }, 400),
    [],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter') {
      setIsOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue(props.initialValues);
    }
  }, [form, props.initialValues]);

  return (
    <div onKeyDown={handleKeyDown}>
      <StyledDropdown
        open={isOpen}
        trigger={['click']}
        getPopupContainer={(node) => node}
        onOpenChange={(value) => setIsOpen(value)}
        dropdownRender={() => (
          <Form
            form={form}
            onValuesChange={onChange}
            initialValues={props.initialValues}
          >
            <Space>
              <Form.Item name="priceMin" style={{ marginBottom: 0 }}>
                <StyledInputNumber
                  controls={false}
                  min={0}
                  max={1000000000}
                  decimalSeparator=","
                  placeholder="from"
                  suffix={currencySymbol}
                />
              </Form.Item>
              <Form.Item name="priceMax" style={{ marginBottom: 0 }}>
                <StyledInputNumber
                  controls={false}
                  min={0}
                  max={1000000000}
                  decimalSeparator=","
                  name="priceMax"
                  placeholder="to"
                  suffix={currencySymbol}
                />
              </Form.Item>
            </Space>
          </Form>
        )}
      >
        <StyledLink
          onClick={(e) => e.preventDefault()}
          className={[isOpen && 'open'].join(' ')}
          tabIndex={0}
          style={props.style}
        >
          {getPriceString(priceMin, priceMax)}
          <Icon name="arrowDown" style={{ fontSize: '11px' }} />
        </StyledLink>
      </StyledDropdown>
    </div>
  );
};

export default React.memo(DropdownPrice);
