import { ComponentType } from 'react';
import styled from 'styled-components';
import { Select as AntSelect, SelectProps } from 'antd';
import { Icon } from '../icon/Icon';

export const StyledSelect = styled(AntSelect)`
  .ant-select-selector {
    &.ant-select-selector {
      color: var(--black);
      padding: 7px 12px 7px 20px;
      height: 48px;
      width: 192px;
      border-radius: 16px;
      border: none;
      box-shadow: none !important;
      &:hover {
        background-color: var(--white-blue);
      }
    }
  }
  &.ant-select.ant-select-focused {
    .ant-select-selector {
      background-color: var(--white-blue) !important;
    }
  }

  .ant-select-item {
    padding: 8px 0px 8px 16px;
    border-radius: 16px;
    color: var(--grey-dark);
    &:hover {
      padding: 8px 16px;
      border-radius: 16px;
      background-color: var(--rgba-blue);
      color: var(--blue);
    }
    &-option-active {
      color: var(--blue);
    }
  }

  .rc-virtual-list-holder-inner {
    gap: 4px;
  }

  .ant-select-dropdown {
    border-radius: 20px;
    padding: 8px;
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: var(--blue);
      background-color: var(--rgba-blue);
      font-weight: 400;
    }
  }

  .ant-select-arrow {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: var(--grey-dark);
    svg {
      fill: currentColor;
    }
    .anticon:not(.ant-select-suffix) {
      pointer-events: none;
    }
  }
  &.ant-select-open {
    .ant-select-arrow {
      transform: rotate(0.5turn);
    }
  }

  .ant-select-selection-placeholder {
    color: var(--grey-dark);
  }

  &.ant-select-compact-item:not(.ant-select-compact-last-item) {
    margin-inline-end: 0;
  }
`;

export const Select = <T,>(props: SelectProps<T>) => {
  return (
    <StyledSelect<ComponentType<SelectProps<T>>>
      defaultActiveFirstOption={false}
      suffixIcon={
        <Icon
          name="arrowDown"
          style={{
            fontSize: 11,
          }}
        />
      }
      {...props}
    />
  );
};
