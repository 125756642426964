import React from 'react';
import { CardProps as AntdCardProps } from 'antd';
import RadioGroup from '../radio-group';
import useTranslate from '../../hooks/use-translate';
import {
  CardTitle,
  RoomButtonWrapper,
  StyledButton,
  StyledCard,
} from './style';
import { Icon } from '../../components-library';

type CardProps = AntdCardProps & {
  bcgImage: string;
  handleChangeRoomFilter: (value: number) => void;
  handleClickLink: () => void;
};

const MainFilterCard = ({
  bcgImage,
  handleChangeRoomFilter,
  handleClickLink,
  ...props
}: CardProps) => {
  const { t } = useTranslate();

  return (
    <StyledCard
      bordered={false}
      cover={
        <>
          <img alt="Villa" src={bcgImage} />
          <StyledButton
            type="secondary"
            icon={<Icon name="arrowUp" style={{ fontSize: '12px' }} />}
            onClick={handleClickLink}
          />
          <CardTitle level={3}>{props.title}</CardTitle>
          <RoomButtonWrapper>
            <RadioGroup
              onChange={handleChangeRoomFilter}
              items={[
                {
                  value: 1,
                  label: `1 ${t('filter.room')}`,
                },
                {
                  value: 2,
                  label: `2 ${t('filter.rooms')}`,
                },
                {
                  value: 3,
                  label: `3 ${t('filter.rooms')}`,
                },
                {
                  value: 4,
                  label: `4 ${t('filter.rooms')}`,
                },
              ]}
            />
          </RoomButtonWrapper>
        </>
      }
    />
  );
};

export default React.memo(MainFilterCard);
