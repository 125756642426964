import { SelectProps, Space } from 'antd';
import useTranslate from '../../hooks/use-translate';
import DropdownPrice, { PriceFormValue } from '../dropdown-price';
import { StyledInput, StyledRoomSelect, StyledSelect } from './style';
import { ComponentType, useState } from 'react';
import { AnnouncementFilter } from '../../app/announcement-list';
import { StyledCheckbox } from '../../components-library/styled';

type FilterGroupProps = {
  onChange?: (
    value: string | number | number[] | string[],
    label?: string,
  ) => void;
  items?: { value: string; label: string }[];
  selected?: AnnouncementFilter;
};

const FilterGroup = (props: FilterGroupProps) => {
  const { t } = useTranslate();
  const [dirty, setDirty] = useState(false);

  const onChangePrice = (value: PriceFormValue) => {
    if (value.priceMin) {
      props.onChange?.(value.priceMin, 'priceMin');
    } else {
      props.onChange?.(value.priceMax, 'priceMax');
    }
  };

  const options = [
    {
      value: 'VILLA',
      label: (
        <StyledCheckbox
          onClick={(e) => {
            if (dirty) {
              e.stopPropagation();
            }
            setDirty(false);
          }}
          checked={props.selected?.realEstateObjectTypes?.includes('VILLA')}
        >
          {t('anno.villa')}
        </StyledCheckbox>
      ),
      title: t('anno.villa'),
    },
    {
      value: 'APARTMENTS',
      label: (
        <StyledCheckbox
          onClick={(e) => {
            if (dirty) {
              e.stopPropagation();
            }
            setDirty(false);
          }}
          checked={props.selected?.realEstateObjectTypes?.includes(
            'APARTMENTS',
          )}
        >
          {t('anno.appartments')}
        </StyledCheckbox>
      ),
      title: t('anno.appartments'),
    },
    {
      value: 'GUESTHOUSE',
      label: (
        <StyledCheckbox
          onClick={(e) => {
            if (dirty) {
              e.stopPropagation();
            }
            setDirty(false);
          }}
          checked={props.selected?.realEstateObjectTypes?.includes(
            'GUESTHOUSE',
          )}
        >
          {t('anno.guesthouse')}
        </StyledCheckbox>
      ),
      title: t('anno.guesthouse'),
    },
    {
      value: 'LAND',
      label: (
        <StyledCheckbox
          onClick={(e) => {
            if (dirty) {
              e.stopPropagation();
            }
            setDirty(false);
          }}
          checked={props.selected?.realEstateObjectTypes?.includes('LAND')}
        >
          {t('anno.land')}
        </StyledCheckbox>
      ),
      title: t('anno.land'),
    },
  ];

  return (
    <Space.Compact style={{ gap: '2px', flex: '0 1 100%' }}>
      <StyledSelect<ComponentType<SelectProps<string[]>>>
        placeholder="Select type"
        maxTagCount="responsive"
        optionLabelProp="title"
        onMouseDown={(e) => {
          setDirty(false);
          e.stopPropagation();
        }}
        showSearch={false}
        mode="multiple"
        getPopupContainer={(node) => node}
        options={options}
        value={props.selected?.realEstateObjectTypes}
        onChange={(value) => {
          props.onChange?.(value, 'realEstateObjectTypes');
          setDirty(true);
        }}
      />
      <StyledRoomSelect
        mode="multiple"
        value={props.selected?.rooms}
        onChange={(value) => props.onChange?.(value, 'rooms')}
        style={{ backgroundColor: 'var(grey-light)' }}
      />
      <DropdownPrice
        onChange={onChangePrice}
        initialValues={{
          priceMin: props.selected?.priceMin,
          priceMax: props.selected?.priceMax,
        }}
        style={{ backgroundColor: 'var(--grey-light' }}
      />
      <StyledInput
        placeholder={t('filter.typeAddress')}
        onChange={(e) => props.onChange?.(e.target.value, 'region')}
      />
    </Space.Compact>
  );
};
export default FilterGroup;
