import { Form } from 'antd';
import styled from 'styled-components';

export const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 24px;
    .ant-form-item-label {
      text-align: start;
    }
    .ant-form-item-label > label {
      font-size: 16px;
    }
  }
`;
