import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 37px;
    padding: 5px 15px;
    border-radius: 10px;
    background-color: var(--white);
    color: var(--black);
    border: none;
    line-height: 1.5;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover {
      background-color: var(--dark-blue);
      color: var(--white);
    }
    &-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: var(--dark-blue);
      color: var(--white);
    }
    &:not(:first-child)::before {
      width: 0;
    }
  }
`;
