import Announcement from '../app/announcement';
import AnnouncementList from '../app/announcement-list';
import CreateAnnouncement from '../app/create-announcement';
import EditAnnouncement from '../app/edit-announcement';
import Home from '../app/home';
import Login from '../app/login';
import MaintenancePage from '../app/maintenance';

export const defaultRoutes = [
  {
    title: 'Main Page',
    path: '',
    element: <Home />,
    protected: true,
  },
  {
    title: 'Главная',
    path: 'maintenance',
    element: <MaintenancePage />,
    protected: false,
  },
  {
    title: 'Log in',
    path: 'login',
    element: <Login />,
    protected: false,
  },
  {
    title: 'Create an announcement',
    path: 'admin/create-anno',
    element: <CreateAnnouncement />,
    protected: true,
  },
  {
    title: 'An announcement',
    path: 'admin/announcement/:id?',
    element: <Announcement />,
    protected: true,
  },
  {
    title: 'Edit an announcement',
    path: 'admin/edit-announcement/:id?',
    element: <EditAnnouncement />,
    protected: true,
  },
  {
    title: 'Search an announcement',
    path: 'admin/announcement-list',
    element: <AnnouncementList />,
    protected: true,
  },
];
