import React, { useState, useEffect } from 'react';
import type { UploadFile, UploadProps } from 'antd';
import { message } from 'antd';
import uploadImage from '../../assets/Villo2.png';
import useTranslate from '../../hooks/use-translate';
import { StyledUpload, TextUpload } from './style';

type UploadImageProps = UploadProps & {
  onUpload: (url: string) => void;
  onDelete: (url?: string) => void;
};

const UploadImage = ({ onUpload, ...props }: UploadImageProps) => {
  const { t } = useTranslate();
  const [value, setValue] = useState<{ fileList?: UploadFile[] }>({
    fileList: props.fileList,
  });

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isPNGorJPG =
        file.type === 'image/png' || file.type === 'image/jpeg';
      if (!isPNGorJPG) {
        message.error(`Поддерживаются только файлы формата PNG или JPG.`);
        return isPNGorJPG;
      }
      const isLessThan2M = file.size / 1024 / 1024 < 2;
      if (!isLessThan2M) {
        message.error('Вес изображения не должен превышать 2 МБ!');
        return isLessThan2M;
      }
      return isPNGorJPG && isLessThan2M;
    },
    onChange: (info) => {
      const fileList = [...info.fileList];
      setValue({ fileList });
      props.onChange?.(info);
    },
    onRemove: (file) => {
      props.onDelete?.(file.url);
    },
    customRequest: async (options) => {
      const { onSuccess, file } = options;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'm9fl7zjd');
      try {
        const response = await fetch(
          'https://api.cloudinary.com/v1_1/dxo2pgih5/image/upload',
          {
            method: 'POST',
            body: formData,
          },
        );
        const data = await response.json();

        onSuccess?.('Ok');
        onUpload?.(data.secure_url);
      } catch (err) {
        console.log('Error: ', err);
      }
    },
    name: 'image',
    fileList: value.fileList,
    listType: props.listType,
    maxCount: props.maxCount,
    multiple: true,
  };

  useEffect(() => {
    if (!props.fileList) return;
    setValue({ fileList: props.fileList });
  }, [props.fileList]);

  return (
    <StyledUpload {...uploadProps}>
      <img src={uploadImage} alt="Upload" style={{ width: '80px' }} />
      <br />
      <TextUpload>{t('anno.addPhoto')}</TextUpload>
    </StyledUpload>
  );
};

export default UploadImage;
