import { Space } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MenuList = styled(Space.Compact)`
  width: 175px;
  padding: 8px;
  border-radius: 20px;
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
`;

export const StyledLink = styled(Link)`
  padding: 8px 16px;
  display: inline-block;
  color: var(--grey-dark);
  &:hover {
    padding: 8px 16px;
    color: var(--blue);
    background-color: rgba(0, 151, 255, 0.07);
    border-radius: 16px;
  }
`;
