import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from '../types/enums';

export type Lang = 'ru' | 'en' | 'id';
const currencySymbols = {
  USD: '$',
  EUR: '€',
  RUB: '₽',
  IDR: 'Rp',
};

const localeSlice = createSlice({
  name: 'locale',
  initialState: {
    lang: 'en' as Lang,
    currency: { code: 'USD' as Currency, symbol: '$' },
  },
  reducers: {
    setLang(state, action: PayloadAction<Lang>) {
      state.lang = action.payload;
    },
    setCurrency(state, action: PayloadAction<Currency>) {
      state.currency = {
        code: action.payload,
        symbol: currencySymbols[action.payload],
      };
    },
  },
});

export default localeSlice;
