import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { User } from '../types/response/users';
import { UserLoginArgs, UserRemindArgs } from '../types/request/users';
import { baseQueryWithJWTToken } from './base';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithJWTToken('/api/v1/'),
  endpoints: (build) => ({
    login: build.mutation<User, UserLoginArgs>({
      query: ({ email, password }) => ({
        url: `login`,
        method: 'POST',
        body: { email, password },
      }),
    }),
    logout: build.mutation<null, null | void>({
      query: () => ({
        url: `logout`,
        method: 'DELETE',
      }),
    }),
    remind: build.mutation<User, UserRemindArgs>({
      query: ({ token }) => {
        return {
          url: `tokens/validate`,
          method: 'POST',
          body: token,
        };
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useRemindMutation } =
  usersApi;
