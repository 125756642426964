import { SelectProps } from 'antd';
import { StyledSelect } from './style';
import { ComponentType } from 'react';

export function RoomSelect(props: SelectProps) {
  const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  return (
    <StyledSelect<ComponentType<SelectProps<number | number[]>>>
      options={options}
      placeholder="Select rooms"
      removeIcon={<></>}
      mode={props.mode}
      showSearch={false}
      getPopupContainer={(node) => node}
      dropdownStyle={{ width: '273px' }}
      popupMatchSelectWidth={false}
      menuItemSelectedIcon={<></>}
      {...props}
    />
  );
}
