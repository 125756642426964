import { Dropdown, InputNumber } from 'antd';
import styled from 'styled-components';

export const StyledDropdown = styled(Dropdown)`
  .ant-dropdown {
    padding: 16px 20px;
    border-radius: 20px;
    background-color: var(--white);
    .ant-select-item {
      width: 41px;
      height: 41px;
      display: flex;
      align-items: center;
      padding: 0;
      border-radius: 8px;
      color: var(--black);
      border: 1px solid var(--grey);
      &:hover {
        padding: 0;
        border-radius: 8px;
        background-color: rgba(0, 151, 255, 0.07);
        color: var(--blue);
      }
      .ant-select-item-option-content {
        text-align: center;
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: var(--blue);
      font-weight: 400;
    }
  }
  .rc-virtual-list-holder-inner {
    flex-direction: row !important;
    gap: 7px;
    flex-wrap: wrap;
  }
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 135px;
  &.ant-input-number-affix-wrapper {
    padding: 0;
    border-color: var(--grey-light-2);
    &:hover {
      border-color: var(--grey);
    }
  }
  .ant-input-number {
    border-radius: 8px;
    padding: 8px 24px 8px 16px;
    input::placeholder {
      color: var(--grey-dark);
    }
  }
`;

export const StyledLink = styled.a`
  display: inline-block;
  width: 192px;
  height: 48px;
  background: var(--white);
  padding: 11.5px 44px 11.5px 16px;
  border-radius: 4px;
  color: var(--grey-dark);
  font-weight: 400;
  position: relative;
  line-height: 1.5;
  border-radius: 0;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  & svg {
    display: flex;
    align-items: center;
    color: var(--grey-dark);
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    inset-inline-start: auto;
    inset-inline-end: 11px;
    height: 14px;
    margin-top: -7px;
    pointer-events: none;
    fill: currentColor;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:hover {
    background: var(--white-blue);
    color: var(--grey-dark);
  }
  &:focus {
    background: var(--white-blue);
    color: var(--grey-dark);
  }
  &.open {
    & svg {
      transform: rotate(0.5turn);
    }
  }
`;
