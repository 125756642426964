import parseParams from '../utils/parse-params';
import queryString, { StringifyOptions } from 'query-string';

export default function stringifyParams(
  params: Record<string, unknown> = {},
  options: StringifyOptions & { withPreviousParams: boolean } = {
    withPreviousParams: false,
  },
) {
  let prevParams = {};
  if (options.withPreviousParams) {
    prevParams = parseParams(window.location.search);
  }

  return queryString.stringify(
    { ...prevParams, ...params },
    { arrayFormat: 'comma', ...options },
  );
}
