import queryString, { ParseOptions } from 'query-string';

export default function parseParams<ParsedType = string | number>(
  params: string,
  options: ParseOptions = {},
) {
  return queryString.parse(params, {
    arrayFormat: 'comma',
    parseNumbers: true,
    ...options,
  }) as queryString.ParsedQuery<ParsedType>;
}
