import { Layout } from 'antd';
import { Header } from 'antd/es/layout/layout';
import styled from 'styled-components';
import { ThemeColors } from '.';

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  margin: 0 auto;
  background: #fff;
  box-sizing: border-box;
`;

export const StyledHeader = styled(Header)<{ theme?: ThemeColors }>`
  height: 88px;
  background: ${(props) =>
    props.theme === 'secondary' ? 'var(--blue)' : 'none'};
`;
