import React from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import villoLogoBlack from '../../assets/Villo_logo_black.png';
import villoLogoWhite from '../../assets/Villo_logo.png';
import Tools from '../tools';
import { Menu } from '../../components-library';
import useTranslate from '../../hooks/use-translate';
import { ThemeColors } from '../../components/layout';

type HeaderProps = {
  theme?: ThemeColors;
};

function Header({ theme }: HeaderProps) {
  const { t } = useTranslate();
  return (
    <div className="container">
      <Row justify="space-between" align="middle">
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/">
            <img
              src={theme === 'secondary' ? villoLogoWhite : villoLogoBlack}
              alt="logo"
              style={{ verticalAlign: 'middle' }}
            />
          </Link>
          <Menu
            theme={theme}
            mode="horizontal"
            items={[
              {
                key: 0,
                label: <Link to="#">{t('menu.buy')}</Link>,
              },
              {
                key: 1,
                label: <Link to="#">{t('menu.rent')}</Link>,
              },
              {
                key: 2,
                label: <Link to="/admin/create-anno">{t('menu.publish')}</Link>,
              },
            ]}
          />
        </Col>
        <Col>
          <Tools theme={theme} />
        </Col>
      </Row>
    </div>
  );
}

export default React.memo(Header);
