import React from 'react';
import { Dropdown } from 'antd';
import useTypedSelector from '../../hooks/use-selector';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../api/users';
import { useDispatch } from 'react-redux';
import sessionSlice from '../../store/session';
import MenuListVertical from '../../components/menu-list-vertical';
import styled from 'styled-components';
import { Icon, Button } from '../../components-library';

const ToolsContainer = styled.div<{ $theme?: string }>`
  background-color: ${(props) =>
    props.$theme === 'secondary' ? 'var(--rgba-white)' : 'var(--rgba-blue)'};
  border-radius: 24px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
`;

const StyledButton = styled(Button)`
  &.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding: 0px;
  }

  &.ant-btn-text:hover:not(:disabled) {
    border-bottom: none;
  }
`;

const Tools = (props: { theme?: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [logout] = useLogoutMutation();

  const isAuth = useTypedSelector((state) => state.session.isAuth);

  const callbacks = {
    onSignIn: () => {
      navigate('login', { state: { back: location.pathname } });
    },
    onSignOut: async () => {
      await logout();
      dispatch(sessionSlice.actions.signOut());
      localStorage.removeItem('token');
    },
  };

  const guestLinks = [
    { link: '#', label: 'Sign Up' },
    { link: '/login', label: 'Log In' },
    { link: '#', label: 'Account' },
    { divider: true },
    { link: '#', label: 'Publish an ad' },
  ];
  const authLinks = [
    { link: '#', label: 'Messages' },
    { link: '#', label: 'Notifications' },
    { link: '#', label: 'Wishlists' },
    { divider: true },
    { link: '#', label: 'Account' },
    { divider: true },
    { link: '#', label: 'Log out', onClick: callbacks.onSignOut },
  ];

  return (
    <ToolsContainer $theme={props.theme}>
      <Dropdown trigger={['click']} dropdownRender={() => <></>}>
        <StyledButton
          type="text"
          icon={
            <Icon
              name="tools"
              color={props.theme === 'secondary' ? 'var(--white)' : ''}
            />
          }
        />
      </Dropdown>
      <MenuListVertical items={isAuth ? authLinks : guestLinks}>
        <StyledButton
          type="text"
          icon={
            <Icon
              name="users"
              color={props.theme === 'secondary' ? 'var(--white)' : ''}
            />
          }
        />
      </MenuListVertical>
    </ToolsContainer>
  );
};

export default React.memo(Tools);
