import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import { AutoComplete } from 'antd';
import { LatLng, AutocompleteAddress } from '../../ad-form';
import { getGoogleMapAddress } from '../../../utils/get-google-map-address';

type PlacesAutocompleteProps = {
  setSelected: (latLng: LatLng) => void;
  handleAutocomplete?: (address: AutocompleteAddress) => void;
};

export const PlacesAutocomplete = ({
  setSelected,
  handleAutocomplete,
}: PlacesAutocompleteProps) => {
  const {
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'id' },
      locationBias: {
        radius: 2000000,
        center: { lat: -2.5972225, lng: 120.7934033 },
      },
    },
    debounce: 250,
  });

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = getLatLng(results[0]);
    const mapAddress = await getGoogleMapAddress({ lat, lng });

    setSelected({ lat, lng });
    handleAutocomplete?.(mapAddress);
  };

  return (
    <AutoComplete
      options={data.map(({ description }) => ({
        value: description,
      }))}
      style={{ width: 800 }}
      onSelect={handleSelect}
      onSearch={(value) => setValue(value)}
      placeholder="Enter a location"
    />
  );
};
