import * as locales from './locales';

export type ILocales = typeof locales;

/**
 * Перевод фразу по словарю
 * @param lang {String} Код языка
 * @param text {String} Текст для перевода
 * @param [plural] {Number} Число для плюрализации
 * @returns {String} Перведенный текст
 */
export default function translate(
  lang: keyof ILocales,
  text: string,
  plural?: number,
): string {
  const result =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locales[lang] && typeof locales[lang][text] !== 'undefined'
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        locales[lang][text]
      : text;

  if (typeof plural !== 'undefined') {
    const key = new Intl.PluralRules(lang).select(plural);
    return result[key] || result;
  }

  return result;
}
