import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Checkbox,
  Form,
  InputNumber,
  Radio,
  Select,
  Input,
  Space,
  FormProps,
  Button,
} from 'antd';
import UploadImage from '../../components/upload';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import useTranslate from '../../hooks/use-translate';
import { RoomSelect } from '../room-select';
import {
  AnnouncementType,
  Currency,
  DealType,
  RealEstateObjectType,
  RealEstateType,
  RentType,
  RoomLayout,
  RoomStatus,
} from '../../types/enums';
import { EditAnnouncementArgs } from '../../types/request/announcement';
import GoogleMapPlaces from '../google-map';
import { ZipCode } from 'use-places-autocomplete';
import { StyledFormItem } from './style';

export type AutocompleteAddress = {
  postIndex: ZipCode;
  address: string;
  url: string;
  coords: LatLng;
};

export type LatLng = {
  lat: number;
  lng: number;
};

type Address = {
  postIndex: ZipCode;
  region: string;
  city: string;
  address1: string;
  address2: string;
  space: string;
  googleMapsLink: string;
};

type Infrastructure = {
  carWash?: boolean;
  carService?: boolean;
  farmacy?: boolean;
  atm?: boolean;
  pool?: boolean;
  bufet?: boolean;
  cafe?: boolean;
  minimarket?: boolean;
  supermarket?: boolean;
  fitnessCenter?: boolean;
};

type ExtraFeatures = {
  windowsToTheYard?: boolean;
  windowsOutside?: boolean;
  loggia?: boolean;
  fridge?: boolean;
  dishwasher?: boolean;
  washingMachine?: boolean;
  tv?: boolean;
  bathtub?: boolean;
  shower?: boolean;
  conditioner?: boolean;
  internet?: boolean;
};

type Advertisement = {
  announcementType: AnnouncementType;
  dealType: DealType;
  rentType?: RentType;
  description: string;
  pledgeToOwner?: {
    value: number;
    currency: Currency;
  };
  price?: {
    value: number;
    currency: Currency;
  };
};

export type AdFormValue = {
  realEstateType: RealEstateType;
  realEstateObjectType: RealEstateObjectType;
  address: Partial<Address>;
  ceilingHeight?: number;
  layout?: RoomLayout;
  roomStatus?: RoomStatus;
  furniture?: boolean;
  numberOfRooms: number;
  totalArea: number;
  livingSpace: number;
  bathroomCount: number;
  floor?: number;
  floorsInTheHouse: number;
  possibleWithAnimals: boolean;
  possibleWithChildren: boolean;
  infrastructure: Infrastructure[];
  extraFeatures: ExtraFeatures[];
  photos: string[];
  videos: { video: string }[];
  contact: string;
} & Advertisement;

type FormAnnouncementProps = FormProps & {
  onFinish?: (data: EditAnnouncementArgs) => Promise<void>;
  onCancel?: () => void;
  isChangeAnnouncement?: boolean;
  buttonSaveTitle?: string;
  currentLatLng?: LatLng;
};

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

function AdForm({
  onFinish,
  onCancel,
  isChangeAnnouncement,
  buttonSaveTitle,
  currentLatLng,
  ...formProps
}: FormAnnouncementProps) {
  const { t } = useTranslate();
  const [form] = Form.useForm<AdFormValue>(formProps.form);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const coordinates = useRef<LatLng>();

  const callbacks = {
    onFinish: async (values: AdFormValue) => {
      const formValues = {
        announcementType: values.announcementType,
        dealType: values.dealType,
        realEstateObject: {
          realEstateType: values.realEstateType,
          realEstateObjectType: values.realEstateObjectType,
          address: {
            ...values.address,
            latitude: coordinates.current && coordinates.current.lat,
            longitude: coordinates.current && coordinates.current.lng,
          },
          ceilingHeight: values.ceilingHeight,
          layout: values.layout,
          roomStatus: values.roomStatus,
          furniture: values.furniture,
          numberOfRooms: values.numberOfRooms,
          totalArea: values.totalArea,
          livingSpace: values.livingSpace,
          bathroomCount: values.bathroomCount,
          floor: values.floor,
          floorsInTheHouse: values.floorsInTheHouse,
          possibleWithAnimals: values.possibleWithAnimals,
          possibleWithChildren: values.possibleWithChildren,
        },
        photos: imageUrls,
        videos: values.videos
          .filter((item) => item?.video !== undefined)
          .map((item) => item.video),
        rentType: values.rentType,
        description: values.description,
        pledgeToOwner: values.pledgeToOwner?.value,
        pledgeCurrency: values.pledgeToOwner?.currency,
        price: values.price?.value,
        priceCurrency: values.price?.currency,
        contact: values.contact,
      };

      if (values.infrastructure?.length > 0) {
        values.infrastructure.forEach((item) => {
          formValues[item as unknown as keyof Infrastructure] = true;
        });
      }
      if (values.extraFeatures?.length > 0) {
        values.extraFeatures.forEach((item) => {
          formValues['realEstateObject'][
            item as unknown as keyof ExtraFeatures
          ] = true;
        });
      }

      onFinish?.(formValues);
    },
    onUploadImages: (url: string) => {
      setImageUrls((prev) => [...prev, url]);
    },
    onRemoveImages: (url?: string) => {
      if (url) {
        setImageUrls((prev) => prev.filter((itemUrl) => itemUrl !== url));
      }
    },
    onSelectAutocompleteAddress: useCallback(
      ({ postIndex, address, url, coords }: AutocompleteAddress) => {
        form.setFieldsValue({
          address: {
            postIndex: postIndex,
            address1: address,
            googleMapsLink: url,
          },
        });
        coordinates.current = { lat: coords.lat, lng: coords.lng };
      },
      [form],
    ),
    onMapClick: useCallback(
      ({ postIndex, address, url, coords }: AutocompleteAddress) => {
        form.setFieldsValue({
          address: {
            postIndex: postIndex,
            address1: address,
            googleMapsLink: url,
          },
        });
        coordinates.current = { lat: coords.lat, lng: coords.lng };
      },
      [form],
    ),
  };

  useEffect(() => {
    if (!formProps.initialValues || !isChangeAnnouncement) return;

    form.setFieldsValue(formProps.initialValues);
    const photos = formProps.initialValues.photos.map((item) => item.url);
    setImageUrls(photos);
  }, [formProps.initialValues, isChangeAnnouncement, form]);

  return (
    <Form
      form={form}
      onFinish={callbacks.onFinish}
      style={{ maxWidth: 1000 }}
      labelWrap
      scrollToFirstError
      labelAlign="left"
      className="anno-form"
      initialValues={{
        videos: [undefined],
        price: { currency: 'USD' },
        pledgeToOwner: { currency: 'USD' },
        ...formProps.initialValues,
      }}
      {...formItemLayout}
    >
      <StyledFormItem
        name="announcementType"
        label={t('anno.announcementType')}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio.Button value="OWNER">{t('anno.owner')}</Radio.Button>
          <Radio.Button value="AGENT">{t('anno.agent')}</Radio.Button>
        </Radio.Group>
      </StyledFormItem>
      <StyledFormItem
        name="dealType"
        label={t('anno.dealType')}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio.Button value="RENT">{t('anno.rent')}</Radio.Button>
          <Radio.Button value="SALE">{t('anno.sale')}</Radio.Button>
        </Radio.Group>
      </StyledFormItem>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.dealType !== currentValues.dealType
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('dealType') === 'RENT' ? (
            <StyledFormItem name="rentType" label={t('anno.rentalType')}>
              <Radio.Group>
                <Radio.Button value="LONG_TERM_RENT">
                  {t('anno.long')}
                </Radio.Button>
                <Radio.Button value="DAILY_RENT">
                  {t('anno.daily')}
                </Radio.Button>
              </Radio.Group>
            </StyledFormItem>
          ) : null
        }
      </Form.Item>
      <StyledFormItem name="realEstateType" label={t('anno.ownershipType')}>
        <Radio.Group>
          <Radio.Button value="RESIDENTIAL">
            {t('anno.residential')}
          </Radio.Button>
          <Radio.Button value="COMMERCIAL">{t('anno.commercial')}</Radio.Button>
        </Radio.Group>
      </StyledFormItem>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.realEstateType !== currentValues.realEstateType
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('realEstateType') === 'RESIDENTIAL' ? (
            <StyledFormItem
              name="realEstateObjectType"
              label={t('anno.objectType')}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="APARTMENTS">{t('anno.appartments')}</Radio>
                  <Radio value="VILLA">{t('anno.villa')}</Radio>
                  <Radio value="GUESTHOUSE">{t('anno.guesthouse')}</Radio>
                  <Radio value="LAND">{t('anno.land')}</Radio>
                </Space>
              </Radio.Group>
            </StyledFormItem>
          ) : (
            <StyledFormItem
              name="realEstateObjectType"
              label={t('anno.objectType')}
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="OFFICE">{t('anno.office')}</Radio>
                  <Radio value="BUILDING">{t('anno.building')}</Radio>
                  <Radio value="COWORKING">{t('anno.coworking')}</Radio>
                </Space>
              </Radio.Group>
            </StyledFormItem>
          )
        }
      </Form.Item>
      <h4 className="form-item-title">{t('address.address')}</h4>
      <GoogleMapPlaces
        handleAutocomplete={callbacks.onSelectAutocompleteAddress}
        handleMapClick={callbacks.onMapClick}
        currentLatLng={currentLatLng as LatLng}
      />
      <StyledFormItem
        name={['address', 'postIndex']}
        label={t('address.postIndex')}
        rules={[{ required: true }]}
        normalize={(value) => {
          return Number(value.replace(/[^0-9]/gi, ''));
        }}
      >
        <Input />
      </StyledFormItem>
      <StyledFormItem name={['address', 'region']} label={t('address.region')}>
        <Input />
      </StyledFormItem>
      <StyledFormItem
        name={['address', 'city']}
        label={t('address.city')}
        rules={[{ required: true }]}
      >
        <Input />
      </StyledFormItem>
      <StyledFormItem
        name={['address', 'address1']}
        label={`${t('address.address')} 1`}
        rules={[{ required: true }]}
      >
        <Input />
      </StyledFormItem>
      <StyledFormItem
        name={['address', 'address2']}
        label={`${t('address.address')} 2`}
      >
        <Input />
      </StyledFormItem>
      <StyledFormItem
        name={['address', 'apartment']}
        label={t('address.apptOfficeSpace')}
      >
        <Input />
      </StyledFormItem>
      <StyledFormItem
        name={['address', 'googleMapsLink']}
        label={t('address.linkOnGoogleMap')}
        rules={[{ required: true, type: 'url' }]}
      >
        <Input />
      </StyledFormItem>
      <h4 className="form-item-title">{t('anno.aboutObject')}</h4>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.realEstateType !== currentValues.realEstateType
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('realEstateType') === 'RESIDENTIAL' ? (
            <>
              <StyledFormItem
                name="numberOfRooms"
                label={t('anno.numberOfRooms')}
              >
                <RoomSelect
                  placeholder={t('anno.quantity')}
                  style={{ width: 'auto' }}
                />
              </StyledFormItem>
              <StyledFormItem
                name="totalArea"
                label={t('anno.totalArea')}
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  max={10000}
                  addonAfter={
                    <span>
                      {t('m')}
                      <sup>2</sup>
                    </span>
                  }
                />
              </StyledFormItem>
              <StyledFormItem name="livingSpace" label={t('anno.livingSpace')}>
                <InputNumber
                  addonAfter={
                    <span>
                      {t('m')}
                      <sup>2</sup>
                    </span>
                  }
                />
              </StyledFormItem>
              <StyledFormItem name="bathroomCount" label={t('anno.bathrooms')}>
                <InputNumber />
              </StyledFormItem>
            </>
          ) : (
            <>
              <StyledFormItem
                name="ceilingHeight"
                label={t('anno.ceilingHeight')}
              >
                <InputNumber
                  min={0}
                  max={100}
                  decimalSeparator=","
                  addonAfter={t('m')}
                />
              </StyledFormItem>
              <StyledFormItem name="layout" label={t('anno.layout')}>
                <Radio.Group>
                  <Radio.Button value="CABINET">
                    {t('anno.cabinet')}
                  </Radio.Button>
                  <Radio.Button value="OPEN_FLOOR">
                    {t('anno.open')}
                  </Radio.Button>
                  <Radio.Button value="CORRIDOR">
                    {t('anno.corridor')}
                  </Radio.Button>
                  <Radio.Button value="MIXED">{t('anno.mixed')}</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
              <StyledFormItem name="roomStatus" label={t('anno.state')}>
                <Select placeholder="Не выбрано">
                  <Select.Option value="OFFICE_DECORATION">
                    {t('anno.officeDecoration')}
                  </Select.Option>
                  <Select.Option value="FOR_FINISHING">
                    {t('anno.prepareForFinishing')}
                  </Select.Option>
                  <Select.Option value="MAJOR_REPAIRS_REQUIRED">
                    {t('anno.majorOverhaul')}
                  </Select.Option>
                  <Select.Option value="REDECORATIONG_REQUIRED">
                    {t('anno.cosmeticRepairs')}
                  </Select.Option>
                </Select>
              </StyledFormItem>
              <StyledFormItem name="furniture" label={t('anno.furniture')}>
                <Radio.Group>
                  <Radio.Button value={true}>{t('anno.yes')}</Radio.Button>
                  <Radio.Button value={false}>{t('no')}</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
              <StyledFormItem
                name="totalArea"
                label={t('anno.totalArea')}
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  max={10000}
                  addonAfter={
                    <span>
                      {t('m')}
                      <sup>2</sup>
                    </span>
                  }
                />
              </StyledFormItem>
            </>
          )
        }
      </Form.Item>
      <StyledFormItem name="floor" label={t('anno.floor')}>
        <InputNumber />
      </StyledFormItem>
      <StyledFormItem
        name="floorsInTheHouse"
        label={t('anno.floorsInTheHouse')}
        rules={[{ required: true }]}
      >
        <InputNumber min={0} max={100} />
      </StyledFormItem>
      <StyledFormItem
        name="possibleWithAnimals"
        label={t('anno.withAnimals')}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio.Button value={true}>{t('yes')}</Radio.Button>
          <Radio.Button value={false}>{t('no')}</Radio.Button>
        </Radio.Group>
      </StyledFormItem>
      <StyledFormItem
        name="possibleWithChildren"
        label={t('anno.withChildren')}
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio.Button value={true}>{t('yes')}</Radio.Button>
          <Radio.Button value={false}>{t('no')}</Radio.Button>
        </Radio.Group>
      </StyledFormItem>
      <h4 className="form-item-title">{t('anno.additionally')}</h4>
      <StyledFormItem name="extraFeatures" label="">
        <Checkbox.Group style={{ display: 'flex', flexDirection: 'row' }}>
          <Space direction="vertical">
            <label>{t('anno.general')}</label>
            <Checkbox value="furniture" style={{ lineHeight: '32px' }}>
              {t('anno.furniture')}
            </Checkbox>
            <Checkbox value="windowsToTheYard" style={{ lineHeight: '32px' }}>
              {t('anno.windowsToTheYard')}
            </Checkbox>
            <Checkbox value="windowsOutside" style={{ lineHeight: '32px' }}>
              {t('anno.windowsToTheStreet')}
            </Checkbox>
            <Checkbox value="loggia" style={{ lineHeight: '32px' }}>
              {t('anno.balcony')}
            </Checkbox>
          </Space>

          <Space direction="vertical" style={{ marginLeft: '48px' }}>
            <label>{t('anno.technique')}</label>
            <Checkbox value="fridge" style={{ lineHeight: '32px' }}>
              {t('anno.fridge')}
            </Checkbox>
            <Checkbox value="dishWasher" style={{ lineHeight: '32px' }}>
              {t('anno.dishwasher')}
            </Checkbox>
            <Checkbox value="washingMachine" style={{ lineHeight: '32px' }}>
              {t('anno.washingMachine')}
            </Checkbox>
            <Checkbox value="tv" style={{ lineHeight: '32px' }}>
              {t('anno.tv')}
            </Checkbox>
          </Space>

          <Space direction="vertical">
            <label>{t('anno.comfort')}</label>
            <Checkbox value="bathtub" style={{ lineHeight: '32px' }}>
              {t('anno.bathroom')}
            </Checkbox>
            <Checkbox value="washer" style={{ lineHeight: '32px' }}>
              {t('anno.shower')}
            </Checkbox>
            <Checkbox value="conditioner" style={{ lineHeight: '32px' }}>
              {t('anno.airConditioner')}
            </Checkbox>
            <Checkbox value="shower" style={{ lineHeight: '32px' }}>
              {t('anno.internet')}
            </Checkbox>
          </Space>
        </Checkbox.Group>
      </StyledFormItem>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.realEstateType !== currentValues.realEstateType
        }
      >
        {({ getFieldValue }) =>
          getFieldValue('realEstateType') === 'COMMERCIAL' && (
            <>
              <h4 className="form-item-title">{t('anno.infrastructure')}</h4>
              <StyledFormItem
                name="infrastructure"
                label={t('anno.additionally')}
              >
                <Checkbox.Group>
                  <Space direction="vertical">
                    <Checkbox value="carWash" style={{ lineHeight: '32px' }}>
                      {t('anno.carWash')}
                    </Checkbox>
                    <Checkbox value="carService" style={{ lineHeight: '32px' }}>
                      {t('anno.carService')}
                    </Checkbox>
                    <Checkbox value="pharmacy" style={{ lineHeight: '32px' }}>
                      {t('anno.pahrmacy')}
                    </Checkbox>
                    <Checkbox value="atm" style={{ lineHeight: '32px' }}>
                      {t('anno.atm')}
                    </Checkbox>
                    <Checkbox value="pool" style={{ lineHeight: '32px' }}>
                      {t('anno.pool')}
                    </Checkbox>
                  </Space>
                  <Space direction="vertical" style={{ marginLeft: '48px' }}>
                    <Checkbox value="bufet" style={{ lineHeight: '32px' }}>
                      {t('anno.buffet')}
                    </Checkbox>
                    <Checkbox value="cafe" style={{ lineHeight: '32px' }}>
                      {t('anno.cafe')}
                    </Checkbox>
                    <Checkbox value="minimarket" style={{ lineHeight: '32px' }}>
                      {t('anno.minimarket')}
                    </Checkbox>
                    <Checkbox
                      value="supermarket"
                      style={{ lineHeight: '32px' }}
                    >
                      {t('anno.supermarket')}
                    </Checkbox>
                    <Checkbox
                      value="fitnessCenter"
                      style={{ lineHeight: '32px' }}
                    >
                      {t('anno.fitnessCenter')}
                    </Checkbox>
                  </Space>
                </Checkbox.Group>
              </StyledFormItem>
            </>
          )
        }
      </Form.Item>
      <h4 className="form-item-title">{t('anno.photo')}</h4>
      <StyledFormItem
        name="photos"
        valuePropName="fileList"
        getValueFromEvent={(event) => event.fileList}
      >
        <UploadImage
          maxCount={10}
          listType="picture-card"
          onUpload={callbacks.onUploadImages}
          onDelete={callbacks.onRemoveImages}
        />
      </StyledFormItem>
      <h4 className="form-item-title">{t('anno.video')}</h4>
      <Form.List name="videos">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space key={key} style={{ display: 'flex' }} align="baseline">
                <StyledFormItem
                  {...restField}
                  name={[name, 'video']}
                  rules={[{ type: 'url' }]}
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: '24px', width: '400px' }}
                >
                  <Input placeholder={t('anno.linkToYoutube')} />
                </StyledFormItem>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <StyledFormItem>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {t('anno.addNewVideo')}
              </Button>
            </StyledFormItem>
          </>
        )}
      </Form.List>
      <h4 className="form-item-title">{t('anno.description')}</h4>
      <StyledFormItem name="description">
        <Input.TextArea
          rows={6}
          placeholder={t('anno.descriptionPlaceholder')}
        />
      </StyledFormItem>
      <h4 className="form-item-title">{t('anno.priceAndTerms')}</h4>
      <StyledFormItem label={t('anno.rentFee')} rules={[{ required: true }]}>
        <Space.Compact>
          <StyledFormItem
            name={['price', 'value']}
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: 200 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            />
          </StyledFormItem>
          <StyledFormItem
            name={['price', 'currency']}
            rules={[{ required: true }]}
          >
            <Select style={{ width: '80px' }}>
              <Select.Option value={'USD'}>USD</Select.Option>
              <Select.Option value={'IDR'}>IDR</Select.Option>
              <Select.Option value={'EUR'}>EUR</Select.Option>
              <Select.Option value={'RUB'}>RUB</Select.Option>
            </Select>
          </StyledFormItem>
        </Space.Compact>
      </StyledFormItem>
      <StyledFormItem label={t('anno.deposit')}>
        <Space.Compact>
          <StyledFormItem name={['pledgeToOwner', 'value']}>
            <InputNumber
              style={{ width: 200 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              }
            />
          </StyledFormItem>
          <StyledFormItem name={['pledgeToOwner', 'currency']}>
            <Select style={{ width: '80px' }}>
              <Select.Option value={'USD'}>USD</Select.Option>
              <Select.Option value={'IDR'}>IDR</Select.Option>
              <Select.Option value={'EUR'}>EUR</Select.Option>
              <Select.Option value={'RUB'}>RUB</Select.Option>
            </Select>
          </StyledFormItem>
        </Space.Compact>
      </StyledFormItem>
      <h4 className="form-item-title">{t('anno.contact')}</h4>
      <StyledFormItem name="contact" rules={[{ required: true }]}>
        <Input />
      </StyledFormItem>
      <div style={{ display: 'flex', margin: '48px 0' }}>
        <Button
          style={{ marginRight: 12 }}
          type="primary"
          size="large"
          htmlType="submit"
        >
          {buttonSaveTitle || 'Save'}
        </Button>
        <Button
          type="default"
          htmlType="button"
          size="large"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}

export default AdForm;
