import styled from 'styled-components';
import { Announcement } from '../../types/response/announcement';
import AdItem from '../ad-item';
import { Row } from 'antd';

type AdListProps = {
  list: Announcement[];
  onDeleteItem?: (id: string | number) => Promise<void>;
};

const AnnouncementList = styled.div`
  display: flex;
  gap: 60px;
  flex-direction: column;
`;

const AdList = ({ list, onDeleteItem }: AdListProps) => {
  if (list.length === 0) return <Row justify="center">No results found.</Row>;

  return (
    <AnnouncementList>
      {list?.map((item) => {
        return <AdItem key={item.id} ad={item} onDeleteItem={onDeleteItem} />;
      })}
    </AnnouncementList>
  );
};

export default AdList;
