import { useCallback } from 'react';
import LogInForm, { LoginFormValues } from '../../components/login/login-form';
import { Form, message } from 'antd';
import { Button } from '../../components-library';
import { useLoginMutation } from '../../api/users';
import { useDispatch } from 'react-redux';
import sessionSlice from '../../store/session';
import { useLocation, useNavigate } from 'react-router-dom';
import LayoutLoginForm from '../../components/login/layout-login-form';

export type LoginType = 'phone' | 'email';

const Login = () => {
  const [form] = Form.useForm();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const callbacks = {
    onFinish: useCallback(
      async (values: LoginFormValues) => {
        try {
          const user = await login({
            email: values.email,
            password: values.password,
          }).unwrap();
          dispatch(sessionSlice.actions.signIn({ user }));
          localStorage.setItem('token', user.token);
          form.resetFields();
          const back =
            location.state?.back && location.state?.back !== location.pathname
              ? location.state?.back
              : '/';
          navigate(back);
        } catch (err) {
          console.log(err);
          message.error('Login failed');
        }
      },
      [login, dispatch, form, location, navigate],
    ),
  };

  return (
    <LayoutLoginForm title="Log in">
      <LogInForm form={form} onFinish={callbacks.onFinish} />
      <Button
        type="primary"
        style={{ marginTop: '30px' }}
        onClick={form.submit}
      >
        Log in
      </Button>
    </LayoutLoginForm>
  );
};

export default Login;
