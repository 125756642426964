import villoLogo from '../../../assets/logo-white.svg';
import { Col, Row, Space } from 'antd';
import { Link } from 'react-router-dom';
import LocaleSelect from '../../locale-select';
import { CopyrightOutlined } from '@ant-design/icons';
import CurrencySelect from '../../currency-select';
import useTranslate from '../../../hooks/use-translate';
import { LinkList, StyledFooter, StyledLink } from './style';

const Footer = () => {
  const { t } = useTranslate();

  return (
    <StyledFooter>
      <div className="container">
        <Row style={{ padding: '50px 0' }}>
          <Col>
            <Link to="/">
              <img
                src={villoLogo}
                alt="logo"
                style={{ verticalAlign: 'middle' }}
                width={212}
              />
            </Link>
          </Col>
          <Col style={{ marginInline: '85px' }}>
            <LinkList>
              <li>
                <StyledLink to="#">{t('footer.aboutUs')}</StyledLink>
              </li>
              <li>
                <StyledLink to="#">{t('footer.careers')}</StyledLink>
              </li>
              <li>
                <StyledLink to="#">Bug Bounty</StyledLink>
              </li>
            </LinkList>
          </Col>
          <Col style={{ marginRight: '85px' }}>
            <LinkList>
              <li>
                <StyledLink to="#">{t('footer.termsOfUse')}</StyledLink>
              </li>
              <li>
                <StyledLink to="#">{t('footer.privacyPolicy')}</StyledLink>
              </li>
              <li>
                <StyledLink to="#">{t('footer.cookiePolicy')}</StyledLink>
              </li>
            </LinkList>
          </Col>
          <Col style={{ marginRight: '85px' }}>
            <LinkList>
              <li>
                <StyledLink to="#">FAQ</StyledLink>
              </li>
              <li>
                <StyledLink to="#">{t('footer.sitemap')}</StyledLink>
              </li>
              <li>
                <StyledLink to="#">{t('footer.helpCenter')}</StyledLink>
              </li>
            </LinkList>
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>
            <CopyrightOutlined /> 2023 Villo, Inc.
          </Col>
          <Space size={40}>
            <LocaleSelect />
            <CurrencySelect />
          </Space>
        </Row>
      </div>
    </StyledFooter>
  );
};
export default Footer;
